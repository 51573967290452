import Highcharts from 'highcharts/highcharts';

// https://www.highcharts.com/docs/chart-concepts/series
interface Series {
  name: string;
  data: any;
}

// https://www.highcharts.com/docs/chart-and-series-types/chart-types
export enum ChartType {
  area = 'area',
  bar = 'bar',
  line = 'line',
  spline = 'spline',
  areaspline = 'areaspline',
  column = 'column',
  pie = 'pie',
  scatter = 'scatter',
  gauge = 'gauge',
  arearange = 'arearange',
  areasplinerange = 'areasplinerange',
  columnrange = 'columnrange',
}

export interface PortfolioCurrency {
  name: string;
  y?: number | string;
  fullName?: string;
  className?: string;
  balance?: string | number;
  percentage?: string | number;
  id?: string;
  selected?: boolean;
  sliced?: boolean;
  isInitial?: boolean;
}

// TODO not sure do we need it
export interface NewPortfolioCurrency {
  balance: number;
  fullName: string;
  id: string;
  name: string;
  percentage: number;
  value: number;
  y?: number;
}

// https://api.highcharts.com/highcharts/chart.events
export interface ChartEvents {
  load: Function;
  render: Function;
  selection: Function;
  [propName: string]: Function;
}

export const updateChart = (
  element: { key: string; chart: any },
  series: Array<Series> = [],
  type: ChartType = ChartType.area,
  chartEvents: ChartEvents = {
    load: () => {},
    render: () => {},
    selection: () => {},
  },
  additionalOptions: any = {},
  chartObjSettings: any = {},
  axisSettings?: {
    xAxis?: any;
    yAxis?: any;
  },
  decimals: number = 8,
): void => {
  if (document.querySelector(`#${element.key}`) && element.chart) {
    // Can only be created on existed element
    if (!element.chart[element.key]) {
      const chartOptions = {
        chart: {
          type,
          events: chartEvents,
          backgroundColor: 'rgba(0,0,0,0)',
          ...chartObjSettings,
        },
        title: { text: undefined },
        legend: { enabled: false },
        tooltip: {
          animation: true,
          style: {
            color: '#fff',
          },
          followPointer: true,
          // positioner: function () {
          //   return { x: 0, y: 40 };
          // },
          formatter: function (): string | number {
            // @ts-ignore
            const value = this.y;
            try {
              return Number(value).toFixed(decimals);
            } catch {
              return value;
            }
          },
          enabled: true,
        },
        xAxis: {
          visible: false,
          ...(axisSettings?.xAxis || {}),
        },
        yAxis: {
          visible: false,
          ...(axisSettings?.yAxis || {}),
        },
        credits: { enabled: false },
        plotOptions: {
          area: {
            animation: true,
          },
          series: {
            softThreshold: true,
            lineWidth: 1,
            marker: {
              enabled: false,
            },
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
        series,
        ...additionalOptions,
      };
      element.chart[element.key] = Highcharts.chart(element.key, chartOptions);
    } else {
      /**
       * Clear series
       */
      if (element.chart[element.key]?.series) {
        element.chart[element.key].series?.forEach((s: { setData: Function }) => s.setData([]));
      }
      /**
       * Set new series
       */
      series.map((s: Series, i: number) => element.chart[element.key].series[i]?.setData(s.data));
    }
  }
};
