import { useEffect, useState } from 'react';
import { debounce } from '#/util';

interface Props {
  isLoading: boolean;
  timeout?: number;
}
export const useLoading = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.isLoading) {
      setIsLoading(true);
    } else {
      props.timeout ? debounce(props.timeout || 0, () => setIsLoading(false))() : setIsLoading(false);
    }
  }, [props.isLoading]);

  return {
    isLoading,
  };
};
