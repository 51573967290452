import React from 'react';

import './../nova-maintenance.scss';

const NovaGeoRestrictions = () => {
  return (
    <div className='nova-maintenance'>
      <i className='far fa-4x fa-lock' style={{ marginBottom: '20px' }} aria-hidden='true'></i>
      <div className='nova-maintenance-text'>
        <h3>
          {_t(
            `We're sorry, but access to this website is currently blocked for visitors from your country.
          We understand this may be inconvenient, and we hope to welcome you back in the future.`,
            'IP_GEO_BLOCK.MESSAGE',
          )}
        </h3>
      </div>
    </div>
  );
};

export default NovaGeoRestrictions;
