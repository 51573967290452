import { createAsyncThunk } from '@reduxjs/toolkit';

import CryptoPayApi from '#/api/cryptoPay';
import Config from '#/config/config';

import { isStatusInclude } from './util';

import { novaToast } from '#/nova/components/other/toast/novaToast';
import { IPrepareFilterData } from '#/nova/components/views/crypto-pay/transactionsTab/types';

const {
  cryptoPayConfig: { failRequestMessage, successRequestMessage },
} = Config;

export const getPayments = createAsyncThunk(
  'payment/getPayments',
  async (params: IPrepareFilterData | undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getPayments(params);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Payments - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);

export const getPaymentAddresses = createAsyncThunk(
  'payment/getPaymentAddresses',
  async (paymentId: string, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getPaymentAddresses(paymentId);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Payment Addresses - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);

export const createPayment = createAsyncThunk(
  'payment/createPayment',
  async (data: any, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).createPayment(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Create Payment - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      novaToast.success(`Create Payment - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);
