import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { RequestStatus } from '#/types/enums';
import { initialConversionsState } from './helpers';
import { ConversionHistory } from '../interfaces';
import { IConversionsState } from './types';

const conversionsSlice = createSlice({
  name: 'conversions',
  initialState: initialConversionsState,
  reducers: {
    setConversionsRequest(state, action: PayloadAction<RequestStatus>) {
      state.conversionsRequest = action.payload;
    },
    setConversions(state, action: PayloadAction<Array<ConversionHistory>>) {
      state.conversions = action.payload;
      state.conversionsRequest = RequestStatus.Success;
    },
    setConversionsNextPageExists(state, action: PayloadAction<boolean>) {
      state.conversionsNextPageExists = action.payload;
    },
  },
});

export const { setConversions, setConversionsRequest, setConversionsNextPageExists } = conversionsSlice.actions;

export default conversionsSlice.reducer;

export const selectConversionsState = (state: Store): IConversionsState => state.trade.conversions;

export const selectConversions: (store: Store) => Array<ConversionHistory> = createDraftSafeSelector(
  selectConversionsState,
  (conversionsState: IConversionsState) => conversionsState.conversions,
);

export const selectConversionsNextPageExists: (store: Store) => boolean = createDraftSafeSelector(
  selectConversionsState,
  (conversionsState: IConversionsState) => conversionsState.conversionsNextPageExists,
);

export const selectConversionsRequest: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectConversionsState,
  (conversionsState: IConversionsState) => conversionsState.conversionsRequest,
);
