import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectGetUserStatus, localStorageKycLastView } from '#reducers/user/user';
import { selectStatusKycProvider, updateVerificationModalType, VerificationModalType } from '#reducers/kyc';
import { RequestStatus } from '#/types/enums';
import useKyc from './useKyc';
import use2Fa from '../settings/use2Fa';

const period = 1000 * 60 * 60; // Each 1 hour show modal

const useVerificationReminder = () => {
  const dispatch = useDispatch();
  const { kycRequired } = useKyc();
  const { is_using_2fa, mfaStatusRequest } = use2Fa();
  const kycStatusRequest = useSelector(selectGetUserStatus);
  const kycProviderStatus = useSelector(selectStatusKycProvider);
  const lastTime = localStorage.getItem(localStorageKycLastView);

  const update = (type: VerificationModalType) => {
    if (Date.now() > Number(lastTime) + period || !Number(lastTime)) {
      dispatch(updateVerificationModalType(type));
      localStorage.setItem(localStorageKycLastView, String(Date.now()));
    }
  };

  useEffect(() => {
    if (
      [kycStatusRequest, mfaStatusRequest, kycProviderStatus].every(
        (_) => ![RequestStatus.Pending, RequestStatus.None].includes(_),
      )
    ) {
      [
        () => !!(kycRequired && !is_using_2fa) && update(VerificationModalType.Both), // Missing both
        () => !!(!kycRequired && !is_using_2fa) && update(VerificationModalType.TwoFa), // Missing 2fa
        () => !!(kycRequired && is_using_2fa) && update(VerificationModalType.Kyc), // Missing KYC
        () => !!(!kycRequired && is_using_2fa) && dispatch(updateVerificationModalType(VerificationModalType.No)), // All verification used
      ].forEach((_) => {
        _();
      });
    }
  }, [mfaStatusRequest, kycStatusRequest, kycProviderStatus]);
};

export default useVerificationReminder;
