import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { selectUser, UserInfo } from '#/store/reducers/user/user';
import { defaultCryptoAddress, initialAddressBookState } from '#/store/reducers/wallets/address-book/helpers';
import { IAddressBookState } from '#/store/reducers/wallets/address-book/types';

import { FavoriteAddressCrypto, FavoriteFiatDestination, PaymentProperties } from '#/types/interfaces';

const addressBookSlice = createSlice({
  name: 'addressBookSlice',
  initialState: initialAddressBookState,
  reducers: {
    setWalletAddressAddModalOpen(state, action: PayloadAction<{ isOpen: boolean; shouldResetCurrency: boolean }>) {
      state.walletAddressAddModalOpen = action.payload.isOpen;
      if (!action.payload.isOpen) {
        state.currentAddressCrypto = {
          ...defaultCryptoAddress,
          currency_id: action.payload.shouldResetCurrency ? '' : state.currentAddressCrypto.currency_id,
        };
      }
    },
    setWalletAddressBookModalOpen(state, action: PayloadAction<boolean>) {
      state.walletAddressBookModalOpen = action.payload;
    },
    setCurrentAddressCrypto(state, action: PayloadAction<FavoriteAddressCrypto>) {
      state.currentAddressCrypto = action.payload;
    },
    setCurrentAddressFiat(state, action: PayloadAction<FavoriteFiatDestination>) {
      state.currentAddressFiat = action.payload;
    },
    setEditableFiatAddress(state, action: PayloadAction<FavoriteFiatDestination>) {
      state.editableFiatAddress = action.payload;
    },
  },
});

export const {
  setWalletAddressAddModalOpen,
  setCurrentAddressCrypto,
  setCurrentAddressFiat,
  setWalletAddressBookModalOpen,
  setEditableFiatAddress,
} = addressBookSlice.actions;

export default addressBookSlice.reducer;

const selectAddressBookState = (state: Store): IAddressBookState => state.wallets.addressBook;

export const selectWalletCryptoAddressBook: (state: Store) => Array<FavoriteAddressCrypto> = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.favorite_addresses_crypto || [],
);

export const selectWalletFiatAddressBook: (state: Store) => Array<FavoriteFiatDestination> & PaymentProperties =
  createDraftSafeSelector(selectUser, (userInfo: UserInfo) => userInfo.favorite_fiat_destinations || []);

export const selectFiatAddressAccountNumber: (state: Store) => Array<string> = createDraftSafeSelector(
  selectUser,
  (userInfo: UserInfo) => userInfo.favorite_fiat_destinations.map((i) => i.beneficiary_account_number),
);

export const selectWalletAddressBookModalOpen: (state: Store) => boolean = createDraftSafeSelector(
  selectAddressBookState,
  (addressBook: IAddressBookState) => addressBook.walletAddressBookModalOpen,
);

export const selectCurrentAddressCrypto: (state: Store) => FavoriteAddressCrypto = createDraftSafeSelector(
  selectAddressBookState,
  (addressBook: IAddressBookState) => addressBook.currentAddressCrypto,
);

export const selectCurrentAddressFiat: (state: Store) => FavoriteFiatDestination = createDraftSafeSelector(
  selectAddressBookState,
  (addressBook: IAddressBookState) => addressBook.currentAddressFiat,
);

export const selectWalletAddressAddModalOpen: (state: Store) => boolean = createDraftSafeSelector(
  selectAddressBookState,
  (addressBook: IAddressBookState) => addressBook.walletAddressAddModalOpen,
);

export const selectEditableFiatAddress: (state: Store) => FavoriteFiatDestination = createDraftSafeSelector(
  selectAddressBookState,
  (addressBook: IAddressBookState) => addressBook.editableFiatAddress,
);
