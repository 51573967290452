import { ReactElement, useEffect, useState } from 'react';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '#reducers/config';
import langConfig from '#/config/language/langConfig';

const NovaWithTranslation = ({ children }: { children: ReactElement }) => {
  const [loadedLanguage, setIsLoaded] = useState<string>('');
  const currentLanguage = useSelector(selectCurrentLanguage);
  const languageName = langConfig.items[currentLanguage];

  useEffect(() => {
    if (loadedLanguage !== currentLanguage && !!languageName) {
      const langHashes = JSON.parse(JSON.stringify(process.env.REACT_APP_LANGUAGE_VERSIONS));

      fetch(`/lang/${currentLanguage}.${langHashes[currentLanguage]}.json`)
        .then((data) => data.json())
        .then((translations) => {
          window.translations = translations;
          setIsLoaded(currentLanguage);
        })
        .catch((e) => novaToast.error(`${langConfig.items[currentLanguage]} translations not loaded`, e));
    }
    if (!languageName) {
      novaToast.error(`Language ${currentLanguage} not found`);
    }
  }, [currentLanguage]);

  return <div key={loadedLanguage}>{children}</div>;
};

export default NovaWithTranslation;
