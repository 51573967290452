import React, { ReactElement, ReactNode } from 'react';

import errorImg from '#images/error.svg';
import NovaComponent from '#/nova/hoc/nova-component/NovaComponent';

import './error-boundary.scss';

const ErrorView = ({ error, errorInfo }: { error: string; errorInfo: any }) => {
  const errorInfoExists = !!errorInfo?.componentStack;
  return (
    <NovaComponent>
      <div className='error-boundary-holder'>
        <img src={errorImg} alt='' />
        <h3>{_t('Something went wrong...', 'ERROR_BOUNDARY.TITLE')}</h3>
        {errorInfoExists && (
          <details>
            {error && error.toString()}
            <br />
            {errorInfo.componentStack}
          </details>
        )}
      </div>
    </NovaComponent>
  );
};

interface Props {
  children: Array<ReactElement | ReactNode> | ReactElement | ReactNode;
}
class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false, error: '', errorInfo: { componentStack: '' } };

  static getDerivedStateFromError(error: string) {
    return { hasError: true, error };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info);
    this.setState({
      hasError: true,
      error,
      errorInfo: info,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      return <ErrorView {...{ error, errorInfo }} />;
    }

    return children || null;
  }
}

export default ErrorBoundary;
