import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { OrderResult } from '../interfaces';
import { OrdersGetTypes, RequestStatus } from '#/types/enums';
import { filledOrderStatuses, inactiveOrderStatuses, openOrderStatuses, initialOrdersState } from './helpers';
import { OrdersState } from './types';

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrdersState,
  reducers: {
    ordersRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.ordersRequestStatus = action.payload;
    },
    updateOrders(state, action: PayloadAction<Array<OrderResult>>) {
      state.orders = action.payload;
      state.ordersRequestStatus = RequestStatus.Success;
    },
    updateNextOrdersExists(state, action: PayloadAction<boolean>) {
      state.nextPageOrdersExists = action.payload;
    },
    setTypeOrders(state, action: PayloadAction<OrdersGetTypes>) {
      state.type = action.payload;
    },
    childOrdersRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.childOrdersRequestStatus = action.payload;
    },
    updateChildOrders(state, action: PayloadAction<Array<OrderResult>>) {
      state.childOrders = action.payload;
      state.childOrdersRequestStatus = RequestStatus.Success;
    },
  },
});

export const {
  ordersRequestStatus,
  updateNextOrdersExists,
  updateOrders,
  setTypeOrders,
  childOrdersRequestStatus,
  updateChildOrders,
} = ordersSlice.actions;

export default ordersSlice.reducer;

export const selectOrdersState = (state: Store): OrdersState => state.trade.orders;

export const selectAllOrders: (state: Store) => Array<OrderResult> = createDraftSafeSelector(
  selectOrdersState,
  (orderState: OrdersState) => orderState.orders,
);

export const selectNextPageOrdersExists: (state: Store) => boolean = createDraftSafeSelector(
  selectOrdersState,
  (orderState: OrdersState) => orderState.nextPageOrdersExists,
);

export const selectFilledOrders: (state: Store) => Array<OrderResult> = createDraftSafeSelector(
  selectAllOrders,
  (orders: Array<OrderResult>) => orders.filter((_) => filledOrderStatuses.includes(_.status)),
);

export const selectClosedOrders: (state: Store) => Array<OrderResult> = createDraftSafeSelector(
  selectAllOrders,
  (orders: Array<OrderResult>) => orders.filter((_) => inactiveOrderStatuses.includes(_.status)),
);

export const selectOpenOrders: (state: Store) => Array<OrderResult> = createDraftSafeSelector(
  selectAllOrders,
  (orders: Array<OrderResult>) => orders.filter((_) => openOrderStatuses.includes(_.status)),
);

export const selectOrdersStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectOrdersState,
  (orderState: OrdersState) => orderState.ordersRequestStatus,
);

export const selectOrdersType: (state: Store) => OrdersGetTypes = createDraftSafeSelector(
  selectOrdersState,
  (orderState: OrdersState) => orderState.type,
);

export const selectChildOrdersStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectOrdersState,
  (orderState: OrdersState) => orderState.childOrdersRequestStatus,
);

export const selectChildOrders: (state: Store) => Array<OrderResult> = createDraftSafeSelector(
  selectOrdersState,
  (orderState: OrdersState) => orderState.childOrders,
);
