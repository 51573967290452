export enum Languages {
  EnUs = 'en-us',
  En = 'en',
  ZhCn = 'zh-CN',
  Por = 'pt',
}

export interface LangItems {
  [langName: string]: string;
}

export interface LangConfig {
  default: Languages;
  items: LangItems;
}

const langConfig: LangConfig = {
  default: Languages.En,
  items: {
    [Languages.En]: 'English',
  },
};

export default langConfig;
