import { updateIsDevelopment } from '#reducers/settings';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UrlSearchParamsEnum } from '#/types';

const useDevelopmentMode = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isDevParam = urlParams.get(UrlSearchParamsEnum.IsDevelopment);

    if (isDevParam) {
      dispatch(updateIsDevelopment(true));
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  return null;
};

export default useDevelopmentMode;
