import { AnaliticsIds } from './analytics';
import { Paths } from '#/config/templates/nova/nova';

export type Nav = {
  title: string;
  translation: string;
  link?: string;
  customLink?: string;
  icon?: string;
  customIcon?: string;
  customIconHover?: string;
  use?: boolean;
  isPulsing?: boolean;
  handler?: () => void;
  id?: string;
  isAuth?: boolean;
  cryptoPay?: boolean;
};

export const sideDesktopNavigation: Array<Nav> = [
  {
    title: 'Dashboard',
    translation: 'NAV.DASHBOARD',
    link: Paths.Dashboard,
    icon: 'fas fa-th-large',
    isAuth: true,
    id: AnaliticsIds.linkHome,
  },
  {
    title: 'Exchange',
    translation: 'NAV.TRADES',
    link: Paths.Exchange,
    icon: 'fas fa-exchange',
    id: AnaliticsIds.linkTrade,
  },
  {
    title: 'Markets',
    translation: 'NAV.MARKETS',
    link: Paths.Markets,
    icon: 'fas fa-chart-bar',
    id: AnaliticsIds.linkMarket,
  },
];

export const sideDesktopBottomNavigation: Array<Nav> = [];

export const sideMobileNavigation: Array<Nav> = [
  {
    title: 'Login',
    translation: 'NAV.LOGIN',
    link: Paths.Login,
    icon: 'fas fa-sign-in-alt',
  },
  {
    title: 'Sign Up',
    translation: 'NAV.SIGNUP',
    link: Paths.Login,
    icon: 'fas fa-user-plus',
  },
  {
    title: 'Dashboard',
    translation: 'NAV.DASHBOARD',
    link: Paths.Dashboard,
    icon: 'fas fa-th-large',
    isAuth: true,
    id: AnaliticsIds.linkHome,
  },
  {
    title: 'Exchange',
    translation: 'NAV.TRADES',
    link: Paths.Exchange,
    icon: 'fas fa-exchange',
    id: AnaliticsIds.linkTrade,
  },
  {
    title: 'Markets',
    translation: 'NAV.MARKETS',
    link: Paths.Markets,
    icon: 'fas fa-chart-bar',
    id: AnaliticsIds.linkMarket,
  },
];

export const headerNavigation: Array<Nav> = [
  {
    title: 'Wallets',
    translation: 'NAV.WALLETS',
    link: Paths.Wallets,
    icon: 'fa fa-wallet',
    isAuth: true,
    id: AnaliticsIds.linkWallet,
  },
  {
    title: 'Settings',
    translation: 'NAV.SETTINGS',
    link: Paths.Settings,
    icon: 'fa fa-wrench',
    isAuth: true,
    id: AnaliticsIds.linkSettings,
  },
  {
    title: 'Support',
    translation: 'NAV.SUPPORT',
    customLink: 'https://support.xcadnetwork.com/hc/en-us',
    icon: 'fad fa-comments',
  },
];
