import { GqlClient } from './gql';
import configEnv from '#/config/config-env';
import TradeService from './trade/TradeService';
import UserService from './user/UserService';
import SettingsService from './settings/SettingsService';
import ThirdPartyApi from './third-party-api/ThirdPartyApi';
import VaultsService from './vaults/VaultsService';
import CryptoPayApi from './cryptoPay';

const gqlRequestClientVako = new GqlClient(configEnv.vakoUrl);
const gqlRequestClientVakoIdToken = new GqlClient(configEnv.vakoUrl);
const gqlRequestClientLending = new GqlClient(configEnv.lendingUrl);

export interface Services {
  gqlRequestClientVako: GqlClient;
  gqlRequestClientLending: GqlClient;
  tradeService: TradeService;
  userService: UserService;
  settingsService: SettingsService;
  lendingService: VaultsService;
  thirdPartyApi: ThirdPartyApi;
}

export default {
  gqlRequestClientVako,
  gqlRequestClientLending,
  gqlRequestClientVakoIdToken,
  tradeService: new TradeService(gqlRequestClientVako),
  userService: new UserService(gqlRequestClientVako, gqlRequestClientVakoIdToken),
  settingsService: new SettingsService(gqlRequestClientVako),
  lendingService: new VaultsService(gqlRequestClientLending),
  thirdPartyApi: new ThirdPartyApi(),
  cryptoPayApi: new CryptoPayApi(),
};
