import { RequestStatus } from '#/types/enums';
import { IWithdrawState } from './types';

export const initialWithdrawState: IWithdrawState = {
  withdrawCrypto: null,
  withdrawFiat: null,
  withdrawRequest: RequestStatus.None,
  withdrawFiatPaymentMethod: null,
  withdrawFiatPaymentRoute: null,
};
