import { ReactElement, LazyExoticComponent, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import useInstruments from '#/hooks/trade/instruments/useInstruments';
import { useAuth } from '#/hooks/auth';

import NovaFallback from '#/nova/fallback/NovaFallback';

import { selectTemplateRoutesConfig } from '#/store/reducers/config';
import { checkUser } from '#/store/reducers/cryptoPay/thunks/user';

import { ITemplateRoute } from '#/config/templates';
import { useCryptoPay } from '#/config/config-env';
import RedirectEffect from '#/router/RedirectEffect';

import { AppDispatch } from '#/store/root';

interface Props {
  components: {
    [name: string]: LazyExoticComponent<any> | ReactElement | Element;
  };
}
const Router = ({ components }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const { isAuth, mfaRequired } = useAuth();
  const routes = useSelector(selectTemplateRoutesConfig);

  useInstruments();

  const renderRoute = (route: ITemplateRoute) => {
    const Component = components[route.component];

    if (route.authRedirect && !isAuth) {
      return (
        <Route
          key={`route-${route.path}`}
          path={route.path}
          index={route.path === '/'}
          element={
            <Suspense fallback={<NovaFallback />}>
              <Navigate to={route.authRedirect} replace />;
            </Suspense>
          }
        />
      );
    }

    if (route.auth && (!isAuth || mfaRequired)) {
      return null;
    }

    return (
      <Route
        key={`route-${route.path}`}
        path={route.path}
        index={route.path === '/'}
        element={
          <Suspense fallback={<NovaFallback />}>
            {/* @ts-ignore */}
            <Component {...route} />
          </Suspense>
        }
      />
    );
  };

  const renderListRoutes = () => routes.map((route: ITemplateRoute) => renderRoute(route)).filter((route) => !!route);

  // ----- check user for crypto pay
  useEffect(() => {
    if (useCryptoPay && isAuth) dispatch(checkUser());
  }, [isAuth]);

  return (
    <BrowserRouter>
      <RedirectEffect />
      <Routes>{renderListRoutes()}</Routes>
    </BrowserRouter>
  );
};

export default Router;
