/**
 * Check if condition true every {timeout} until resolve or {maxTimeout} reached
 *
 * @param condition
 * @param timeout
 * @param maxTimeout
 * @return period before resolved
 */
export const waitCondition = (
  condition: () => boolean,
  timeout: number = 300,
  maxTimeout: number = 5000,
): Promise<number> => {
  let period: number = 0;
  return new Promise((res, rej) => {
    const recursive: () => void = () => {
      period += timeout;
      if (!condition() && period <= maxTimeout) {
        return setTimeout(recursive, timeout);
      }
      return res(period);
    };
    recursive();
  });
};
