import { useState, useEffect, useCallback } from 'react';
import { TradePager } from '#/types/interfaces';

interface UserPagerResult {
  page: number;
  setPage: (page: number) => void;
  pager: TradePager;
}

export const defaultPager = { limit: 7, offset: 0 };

export const usePager = (_pager?: TradePager): UserPagerResult => {
  const [pager, _setPager] = useState<TradePager>(_pager || defaultPager);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    _setPager({
      offset: (page - 1) * pager.limit,
      limit: pager.limit,
    });
  }, [page]);

  return {
    page,
    pager,
    setPage: useCallback(setPage, []),
  };
};
