import * as gql from 'gql-query-builder';
import { CreateKycForm } from './dto';
import { getSsoSettingsFields, getCountryFields, getProvinceFields, getNotificationSettingsFields } from './fields';
import { getKycInfoFields } from '../user/fields';

export * from './dto';

export enum SettingsOperations {
  GetSsoSettings = 'sso_settings',
  CreateKycForm = 'create_kyc_user_data',
  UpdateKycForm = 'update_kyc_user_data',
  GetCountryList = 'countries',
  GetProvinceList = 'provinces',
  GetNotificationSettingsList = 'notification_settings',
  RestrictionsCheck = 'restrictions_check',
}

export default {
  getSsoSettings: () => {
    return gql.query({
      operation: SettingsOperations.GetSsoSettings,
      fields: getSsoSettingsFields,
    });
  },

  createKycForm: (params: CreateKycForm) => {
    return gql.mutation({
      operation: SettingsOperations.CreateKycForm,
      variables: {
        kyc_data: {
          value: params.kycData,
          required: true,
          type: '[KycUserDataEntryInput!]',
        },
        user_id: { value: params.userId, required: true, type: 'String' },
      },
      fields: getKycInfoFields,
    });
  },

  updateKycForm: (params: CreateKycForm) => {
    return gql.mutation({
      operation: SettingsOperations.UpdateKycForm,
      variables: {
        kyc_data: {
          value: params.kycData,
          required: true,
          type: '[KycUserDataEntryInput!]',
        },
        user_id: { value: params.userId, required: true, type: 'String' },
      },
      fields: getKycInfoFields,
    });
  },

  getCountryList: () => {
    return gql.query({
      operation: SettingsOperations.GetCountryList,
      fields: getCountryFields,
    });
  },

  getProvinceList: (countryCode: string) => {
    return gql.query({
      operation: SettingsOperations.GetProvinceList,
      variables: {
        country_code: { value: countryCode, required: true },
      },
      fields: getProvinceFields,
    });
  },

  getEnabledNotifications: () =>
    gql.query({
      operation: SettingsOperations.GetNotificationSettingsList,
      fields: getNotificationSettingsFields,
    }),

  restrictionsCheck: () => {
    return gql.mutation({
      operation: SettingsOperations.RestrictionsCheck,
      fields: [],
    });
  },
};
