import { initialAccountActivityState } from './account-activity/helper';
import { AccountActivityState } from './account-activity/types';
import { ArticlesState, initialArticlesState } from './articles';
import { initialPortfolioHistoryState, PortfolioHistoryState } from './portfolio-history';

export interface IHomeState {
  articles: ArticlesState;
  portfolioHistory: PortfolioHistoryState;
  accountActivity: AccountActivityState;
}

export const initialHomeState: IHomeState = {
  articles: initialArticlesState,
  portfolioHistory: initialPortfolioHistoryState,
  accountActivity: initialAccountActivityState,
};
