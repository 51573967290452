import { BigNumber } from 'bignumber.js';
import { toPositiveBigNumber } from '#/util';

export const zeroBigNumber = new BigNumber(0);

export const divideAmountOnBalance = (
  balance: BigNumber = zeroBigNumber,
  amount: BigNumber = zeroBigNumber,
): BigNumber => amount.dividedBy(balance);

export const fromAmountToPercentage = (amount: BigNumber = zeroBigNumber, balance: BigNumber) => {
  if (balance.isEqualTo(zeroBigNumber)) {
    return 0;
  }
  return Math.round(
    divideAmountOnBalance(balance, amount.isGreaterThan(zeroBigNumber) ? amount : zeroBigNumber)
      .multipliedBy(100)
      .toNumber(),
  );
};

export const fromPercentageToAmount = (percentage: number = 0, balance: BigNumber) =>
  balance.multipliedBy(percentage / 100).toFixed(12);

interface RecalculatePayload {
  amountToSet: BigNumber;
  amountSet: BigNumber;
  percentageToSet: number;
  percentageSet: number;
  setPercentageCallback: Function;
  setAmountCallback: Function;
  balance: BigNumber;
}
export const recalculate = ({
  amountToSet,
  amountSet,
  percentageToSet,
  percentageSet,
  setPercentageCallback,
  setAmountCallback,
  balance,
}: RecalculatePayload) => {
  let updatedPercentage = percentageToSet;
  let updatedAmount: BigNumber = toPositiveBigNumber(amountToSet);
  if (!amountToSet.isEqualTo(amountSet)) {
    // Changed amount
    updatedPercentage = fromAmountToPercentage(amountToSet, balance);
  } else if (percentageToSet !== percentageSet) {
    // Changed percentage
    updatedAmount = toPositiveBigNumber(fromPercentageToAmount(percentageToSet, balance));
  }
  setPercentageCallback(updatedPercentage);
  setAmountCallback(updatedAmount);
};
