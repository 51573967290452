import { useEffect } from 'react';

export const notificationApiSupported = () => window.Notification && navigator?.serviceWorker && window.PushManager;

export const useNotificationsApi = () => {
  function askPermissions() {
    try {
      Notification.requestPermission().then((permission: NotificationPermission) => {});
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    if (notificationApiSupported()) {
      askPermissions();
    }
  }, []);
};
