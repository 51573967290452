export enum ExchangePageOrderEntryTypes {
  Simple = 'simple',
  Limit = 'limit',
  GTD = 'gtd',
}

export const setOrderEntryHeightStyle = (type: ExchangePageOrderEntryTypes) => {
  const exchange: any = document.querySelector('.nova-exchange .nova-markup-top');

  const styles = {
    [ExchangePageOrderEntryTypes.Simple]: '0.1fr auto 435px',
    [ExchangePageOrderEntryTypes.Limit]: '0.1fr auto 475px',
    [ExchangePageOrderEntryTypes.GTD]: '0.1fr auto 550px',
  };

  if (exchange) {
    exchange.style['grid-template-rows'] = styles[type];
  }
};
