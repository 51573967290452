import { useEffect, useState } from 'react';

interface Props {
  maxWidth: number;
}

export const useDisplay = (props: Props) => {
  const [onDisplay, setOnDisplay] = useState(true);

  useEffect(() => {
    const onDisplaySetter = () => {
      if (window.innerWidth > props.maxWidth) {
        setOnDisplay(true);
        return;
      }
      setOnDisplay(false);
    };
    window.addEventListener('resize', onDisplaySetter);
    return () => window.removeEventListener('resize', onDisplaySetter);
  }, []);

  return {
    onDisplay,
  };
};
