/**
 * Use function from args to update object's keys
 * @param object
 * @param functionTransform
 */
export function prepareObjectKeysToRequest(object: any, functionTransform: (property: string) => string) {
  const preparedObject: any = {};
  for (const property in object) {
    preparedObject[functionTransform(property)] = object[property];
  }
  return preparedObject;
}

/**
 * Update object's keys to be lowercase
 * @param object
 */
export function lowercaseObjectKeys(object: any) {
  return prepareObjectKeysToRequest(object, (property) => property.toLowerCase());
}
