import { batch } from 'react-redux';
import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import TradeService from '#/api/trade/TradeService';
import { GetOrders, OrderResult } from '../interfaces';
import { RequestStatus, OrdersGetTypes } from '#/types/enums';
import { debounce, handleErrorThunk, throttle } from '#/util';
import {
  childOrdersRequestStatus,
  ordersRequestStatus,
  updateChildOrders,
  updateNextOrdersExists,
  updateOrders,
} from './orders';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';
import { OrdersPayload } from '#/api/trade/dto';

export const getOrders = createAsyncThunk(
  'trade/getOrders',
  debounce(1500, async (params: GetOrders, { dispatch, extra }: any) => {
    try {
      const result: {
        open_orders?: Array<OrderResult>;
        closed_orders?: Array<OrderResult>;
      } =
        params.type === OrdersGetTypes.Open
          ? await (extra.tradeService as TradeService).openOrders(preparePagerDifferencePlus1(params) as GetOrders)
          : await (extra.tradeService as TradeService).closedOrders(preparePagerDifferencePlus1(params) as GetOrders);
      batch(() => {
        // @ts-ignore
        const orders: Array<OrderResult> = result.open_orders || result.closed_orders;
        const pagerOrders: Array<OrderResult> = prepareResultPagerDifference(orders, params?.pager?.limit);
        dispatch(updateOrders(pagerOrders));
        dispatch(updateNextOrdersExists(orders.length > pagerOrders.length));
      });
    } catch (error) {
      dispatch(ordersRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get orders failed', dispatch);
    }
  }),
) as unknown as (params: GetOrders) => AnyAction;

export const getChildOrders = createAsyncThunk(
  'trade/getChildOrders',
  throttle(async (params: OrdersPayload, { dispatch, extra }: any) => {
    dispatch(childOrdersRequestStatus(RequestStatus.Pending));
    try {
      const { open_orders } = await (extra.tradeService as TradeService).openOrders(params as GetOrders);
      const closedOrders =
        (!open_orders.length &&
          (await (extra.tradeService as TradeService).closedOrders(params as GetOrders)).closed_orders) ||
        [];

      dispatch(updateChildOrders(open_orders.length ? open_orders : closedOrders));
    } catch (error) {
      dispatch(childOrdersRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get child orders failed', dispatch);
    }
  }, 500),
) as unknown as (params: OrdersPayload) => AnyAction;
