import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { throttle, handleErrorThunk } from '#/util';
import { updateArticles, updateRequestStatus } from './articles';
import ThirdPartyApi from '#/api/third-party-api/ThirdPartyApi';
import { RequestStatus } from '#/types';

export const getArticles = createAsyncThunk(
  'home/getArticles',
  throttle(async (_: any, { dispatch, extra }: any) => {
    try {
      dispatch(updateRequestStatus(RequestStatus.Pending));
      const { articles } = await (extra.thirdPartyApi as ThirdPartyApi).loadArticles();
      dispatch(updateArticles(articles));
    } catch (error) {
      handleErrorThunk(error, 'Get news failed', dispatch);
      dispatch(updateRequestStatus(RequestStatus.Failed));
    }
  }, 1500),
) as unknown as () => AnyAction;
