import {
  VaultHistory,
  VaultHistoryQuery,
  VaultProduct,
  VaultPendingTransaction,
  VaultBalance,
  VaultDepositWithdrawPayload,
  VaultDepositWithdrawResponse,
} from '#/types';
import { GqlClient } from '../gql';
import vaultsGql from './vaults-gql';

export default class VaultsService {
  public gqlRequestClient: GqlClient;

  constructor(gqlRequestClient: GqlClient) {
    this.gqlRequestClient = gqlRequestClient;
  }

  getProducts(): Promise<{ productList: Array<VaultProduct> }> {
    return this.gqlRequestClient.request(vaultsGql.getProducts());
  }

  getHistory(params: VaultHistoryQuery): Promise<{ lendingHistory: Array<VaultHistory> }> {
    return this.gqlRequestClient.request(vaultsGql.getHistory(params));
  }

  getPendingTransactions(): Promise<{
    pendingTransactions: Array<VaultPendingTransaction>;
  }> {
    return this.gqlRequestClient.request(vaultsGql.getPendingTransactions());
  }

  getBalances(): Promise<{ lendingBalances: Array<VaultBalance> }> {
    return this.gqlRequestClient.request(vaultsGql.getBalances());
  }

  makeDeposit(params: VaultDepositWithdrawPayload): Promise<VaultDepositWithdrawResponse> {
    return this.gqlRequestClient.request(vaultsGql.makeDeposit(params));
  }

  makeWithdraw(params: VaultDepositWithdrawPayload): Promise<VaultDepositWithdrawResponse> {
    return this.gqlRequestClient.request(vaultsGql.makeWithdraw(params));
  }
}
