import { createAsyncThunk } from '@reduxjs/toolkit';

import CryptoPayApi from '#/api/cryptoPay';
import Config from '#/config/config';

import { isStatusInclude } from './util';

import { novaToast } from '#/nova/components/other/toast/novaToast';

const {
  cryptoPayConfig: { failRequestMessage, successRequestMessage },
} = Config;

export const getCurrencies = createAsyncThunk(
  'currency/getCurrencies',
  async (_: undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getCurrencies();

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Currencies - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);

export const getCurrenciesById = createAsyncThunk(
  'currency/getCurrenciesById',
  async (currencyId: string, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getCurrenciesById(currencyId);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Currencies By Id - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);
