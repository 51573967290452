export enum PortfolioChartTimeframes {
  Week = 'week',
  Month = 'month',
  Year = 'year',
  All = 'all',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
}

const historyChartTimeframes = {
  [PortfolioChartTimeframes.Week]: {
    key: PortfolioChartTimeframes.Week,
    intervalInHours: 2,
    lengthInHours: 168,
    intervalInMS: 7200000,
    lengthInMS: 604800000,
    translate: 'PORTFOLIO_OVERVIEW.HISTORY_CHART_TIMEFRAME_LAST_WEEK',
  },
  [PortfolioChartTimeframes.Month]: {
    key: PortfolioChartTimeframes.Month,
    intervalInHours: 6,
    lengthInHours: 730,
    intervalInMS: 21600000,
    lengthInMS: 2628000000,
    translate: 'PORTFOLIO_OVERVIEW.HISTORY_CHART_TIMEFRAME_LAST_MONTH',
  },
  [PortfolioChartTimeframes.Year]: {
    key: PortfolioChartTimeframes.Year,
    intervalInHours: 24,
    lengthInHours: 8760,
    intervalInMS: 86400000,
    lengthInMS: 31536000000,
    translate: 'PORTFOLIO_OVERVIEW.HISTORY_CHART_TIMEFRAME_LAST_YEAR',
  },
  [PortfolioChartTimeframes.All]: {
    key: PortfolioChartTimeframes.All,
    intervalInHours: 24,
    intervalInMS: 86400000,
    lengthInHours: null,
    translate: 'PORTFOLIO_OVERVIEW.HISTORY_CHART_TIMEFRAME_ALL',
  },
};

export default historyChartTimeframes;
