import translate from '#/util/translation/translate';

declare global {
  interface Window {
    _t: any;
    translations: any;
  }
}

window._t = translate();
window._marketPrices = {};

declare global {
  interface Window {
    attachEvent: any;
    _marketPrices: object;
  }
}

export default {};
