import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  restrictionsCheck,
  selectIsGeoBlock,
  selectIsIpBlacklisting,
  selectMaintenanceMessage,
  selectMaintenanceMode,
  selectRestrictionCheckStatus,
} from '#reducers/settings';
import { RequestStatus } from '#/types';

export interface UseMaintenanceResult {
  maintenanceMessage: string;
  restrictionCheckStatus: RequestStatus;
  isMaintenance: boolean;
  isIpBlacklisting: boolean;
  isGeoBlock: boolean;
}
const useMaintenance = (): UseMaintenanceResult => {
  const dispatch = useDispatch();
  const isMaintenance = useSelector(selectMaintenanceMode);
  const isIpBlacklisting = useSelector(selectIsIpBlacklisting);
  const isGeoBlock = useSelector(selectIsGeoBlock);
  const maintenanceMessage =
    useSelector(selectMaintenanceMessage) || _t('We are working on issues..', 'MAINTENANCE.FIXING_ISSUES');
  const restrictionCheckStatus = useSelector(selectRestrictionCheckStatus);

  useEffect(() => {
    dispatch(restrictionsCheck());
  }, []);

  return {
    maintenanceMessage,
    restrictionCheckStatus,
    isMaintenance,
    isIpBlacklisting,
    isGeoBlock,
  };
};

export default useMaintenance;
