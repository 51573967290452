import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoginPayload } from '#/api/user/user-gql';
import { selectAuthStatus, selectVerifyTwoFaRequest, login as loginAction, selectIsAuth } from '#reducers/user/user';
import { selectConfigAuth, updateAuthType, selectSsoSettings } from '#reducers/config';
import { getSsoSettings } from '#reducers/settings';
import { AuthOptions, keyPrevAuthType } from '#/config/auth';

export const useAuth = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const authConfig = useSelector(selectConfigAuth);
  const isAuth = useSelector(selectIsAuth);
  const ssoSettings = useSelector(selectSsoSettings);
  const mfaRequired = useSelector(selectVerifyTwoFaRequest) === 1;
  const prevAuthType = localStorage.getItem(keyPrevAuthType);
  const currentAuthType = authConfig.type;

  useEffect(() => {
    if (ssoSettings.client_id === '' && ssoSettings.client_id !== undefined) {
      dispatch(getSsoSettings());
    }
  }, []);

  useEffect(() => {
    if (prevAuthType && prevAuthType !== currentAuthType) {
      changeCurrentAuthType(prevAuthType as AuthOptions);
    }
  }, [prevAuthType]);

  const login = (params: LoginPayload): void => {
    dispatch(loginAction(params));
  };

  const changeCurrentAuthType = (authType: AuthOptions) => {
    localStorage.setItem(keyPrevAuthType, authType);
    dispatch(updateAuthType(authType));
  };

  return {
    isAuth,
    login: useCallback(login, []),
    changeCurrentAuthType: useCallback(changeCurrentAuthType, []),
    mfaRequired,
    ssoSettings,
    authStatus,
    authConfig,
    currentAuthType,
  };
};
