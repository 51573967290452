import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { IPaymentResult } from '#reducers/trade/interfaces';
import { RequestStatus } from '#/types/enums';
import { Store } from '#/store/defaultState';
import { initialUserPaymentsState } from './helpers';
import { IPaymentsState } from './types';

const userPaymentsSlice = createSlice({
  name: 'userPaymentsSlice',
  initialState: initialUserPaymentsState,
  reducers: {
    setPayments(state, action: PayloadAction<Array<IPaymentResult>>) {
      state.payments = action.payload;
      state.paymentsStatus = RequestStatus.Success;
    },
    setPaymentsStatus(state, action: PayloadAction<RequestStatus>) {
      state.paymentsStatus = action.payload;
    },
    setPaymentsNextPageExists(state, action: PayloadAction<boolean>) {
      state.paymentsNextPageExists = action.payload;
    },
  },
});

export const { setPayments, setPaymentsStatus, setPaymentsNextPageExists } = userPaymentsSlice.actions;

export default userPaymentsSlice.reducer;

const selectPaymentsState = (state: Store): IPaymentsState => state.wallets.payments;

export const selectPayments: (state: Store) => Array<IPaymentResult> = createDraftSafeSelector(
  selectPaymentsState,
  (userWalletsState: IPaymentsState) => userWalletsState.payments,
);

export const selectPaymentsNextPageExists: (state: Store) => boolean = createDraftSafeSelector(
  selectPaymentsState,
  (userWalletsState: IPaymentsState) => userWalletsState.paymentsNextPageExists,
);

export const selectPaymentsStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectPaymentsState,
  (userWalletsState: IPaymentsState) => userWalletsState.paymentsStatus,
);
