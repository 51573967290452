import { ThunkDispatch, ListenerEffectAPI, Action } from '@reduxjs/toolkit';
import { differenceInMinutes } from 'date-fns';

import { Store } from '#/store/defaultState';
import { refreshCognitoToken, selectTokenExpires, selectTokenRefreshing, updateTokenRefreshing } from '../user/user';
import { selectSsoSettings } from '#reducers/config';

const REFRESH_TOKEN_REST_TIME = 5;
const DEFAULT_TIME_DIFFERENCE = 15;

export const refreshTokenListenerEffect = (
  action: Action,
  listenerApi: ListenerEffectAPI<Store, ThunkDispatch<Store, unknown, Action>>,
) => {
  const state: Store = listenerApi.getState();
  const timeDifference = selectTokenExpires(state)
    ? differenceInMinutes(new Date(Number(selectTokenExpires(state))), new Date()) - 43
    : DEFAULT_TIME_DIFFERENCE;
  const isSsoConfigExists = selectSsoSettings(state);
  const isTokenRefreshing = selectTokenRefreshing(state);
  const isTimeToRefreshCognitoToken = timeDifference <= REFRESH_TOKEN_REST_TIME;
  if (isSsoConfigExists && isTimeToRefreshCognitoToken && !isTokenRefreshing) {
    listenerApi.dispatch(updateTokenRefreshing(true));
    listenerApi.dispatch(refreshCognitoToken());
  }
};
