import { novaToast } from '#/nova/components/other/toast/novaToast';
import { AuthStorageItems, logout, updateIsInactive } from '#reducers/user/user';
import { Dispatch } from '@reduxjs/toolkit';
import { RequestStatus } from '#/types';
import { IErrorResponse, IExtensions } from './typesError';
import {
  updateGeoBlockMode,
  updateIpBlacklistingMode,
  updateMaintenanceMessage,
  updateMaintenanceMode,
} from '#reducers/settings';

enum ERestrictionTriggers {
  IpBlacklisting = 'ip_blacklist',
  GeoBlock = 'geo_block',
  Maintenance = 'maintenance_mode',
}

const errorManager = {
  // ---- Returns an array of errors received from the request, or new Error constructor
  prepareError(objError: any, dispatch: Dispatch) {
    const errors = objError.message ? objError.message : this._handleErrorObj(objError);
    const restriction = this._handleGuardBlock(objError);

    this._actionsAfterError({ errors, restriction }, dispatch);
    return this._prepareErrorToView(errors);
  },

  // ---- handle error guard
  _handleGuardBlock(objError: IErrorResponse) {
    return objError.errors && objError.errors.length > 0 ? objError.errors.map((errors) => errors?.extensions) : [];
  },

  // ----- select which an error will be displayed
  _handleErrorObj(objErrors: IErrorResponse) {
    const errors: Array<string> = [];

    try {
      if (objErrors && objErrors.errors && objErrors.errors.length > 0) {
        objErrors.errors.forEach(({ message, extensions }: { message: string; extensions: any }) => {
          const errorMessage =
            extensions && extensions.exception && extensions.exception.userFriendlyMessage
              ? extensions.exception.userFriendlyMessage
              : this._parseNestingErrors(message);
          errors.push(errorMessage);
        });
      }
    } catch {
      errors.push(JSON.stringify(objErrors));
    }

    return errors;
  },

  // ---- nested errors are extracted if there are any
  _parseNestingErrors(strError: string) {
    const dividerStart = '{"response":';
    const splittedErrors = strError.split(dividerStart);

    if (splittedErrors.length >= 2) {
      const nestingObj = JSON.parse(`${dividerStart}${splittedErrors[1]}`).response;
      return this._handleErrorObj(nestingObj);
    }
    return strError;
  },

  // ---- certain actions are performed when a specific error occurs
  _actionsAfterError({ errors, restriction }: { errors: string[]; restriction: IExtensions[] }, dispatch: Dispatch) {
    if (errors.find((_) => _ === 'USER_IS_INACTIVE')) {
      dispatch(updateIsInactive(true));
    }

    if (restriction.find((_) => _?.code === ERestrictionTriggers.IpBlacklisting)) {
      dispatch(updateIpBlacklistingMode(true));
      const isLogin = !!window.localStorage.getItem(AuthStorageItems.Token);
      isLogin && dispatch(logout());
    }

    if (restriction.find((_) => _?.code === ERestrictionTriggers.GeoBlock)) {
      dispatch(updateGeoBlockMode(true));
      const isLogin = !!window.localStorage.getItem(AuthStorageItems.Token);
      isLogin && dispatch(logout());
    }

    if (restriction.find((_) => _?.code === ERestrictionTriggers.Maintenance)) {
      const maintenanceMessage = restriction.find((_) => _?.code === ERestrictionTriggers.Maintenance)?.message;
      dispatch(updateMaintenanceMode(true));
      dispatch(updateMaintenanceMessage(maintenanceMessage || ''));
      const isLogin = !!window.localStorage.getItem(AuthStorageItems.Token);
      isLogin && dispatch(logout());
    }
  },

  // ---- The error message is replaced with another if necessary.
  _prepareErrorToView(errors: string[]) {
    const errorToView = {
      ['Forbidden ip blacklisting']: _t(`You don't have access `, 'IP_BLACKLISTING.YOU_DONT_HAVE_ACCESS'),
    };

    return this._prepareToastDetailsFromArray(errors.map((value) => (errorToView as any)[value] || value));
  },

  // ---- Preparing of errors for display in the novaToast
  _prepareToastDetailsFromArray(arrErrors: Array<string>) {
    return arrErrors.reduce(
      (acc, _: string) => {
        acc[_] = undefined;
        return acc;
      },
      {} as { [key: string]: string | undefined },
    );
  },
};

export const handleErrorThunk = (objError: any, message: string, dispatch: Dispatch) => {
  novaToast.error(message, errorManager.prepareError(objError, dispatch));
};

export const handleChangePasswordError = (errorMessage: string = '', status: RequestStatus) => {
  const passwordError = errorMessage.toLowerCase();
  const isFailed = status === RequestStatus.Failed;
  if (passwordError.includes('incorrect') && isFailed) {
    return _t('Incorrect password', 'SETTINGS_PROFILE.ERROR_INCORRECT_PASSWORD');
  }

  return !!errorMessage && isFailed ? errorMessage : undefined;
};
