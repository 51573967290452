export type DateType = Date | number | string;

export enum TimeZoneTypes {
  Long = 'long',
  Short = 'short',
  ShortOffset = 'shortOffset',
  LongOffset = 'longOffset',
  ShortGeneric = 'shortGeneric',
  LongGeneric = 'longGeneric',
}

export const TimeZoneName = 'timeZoneName';
