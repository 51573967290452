import React, { ReactElement } from 'react';
import useMaintenance from '#/hooks/settings/useMaintenance';
import { RequestStatus } from '#/types';

import NovaFallback from '#/nova/fallback/NovaFallback';
import NovaIpBlacklisting from './ip-blacklisting/IpBlacklisting';
import NovaGeoRestrictions from './geo-block/GeoRestrictions';

import './nova-maintenance.scss';

interface Props {
  children: ReactElement;
}

const NovaMaintenance = ({ children }: Props) => {
  const { maintenanceMessage, restrictionCheckStatus, isIpBlacklisting, isGeoBlock, isMaintenance } = useMaintenance();

  if (isGeoBlock) {
    return <NovaGeoRestrictions />;
  }

  if (isIpBlacklisting) {
    return <NovaIpBlacklisting />;
  }

  if (restrictionCheckStatus === RequestStatus.Pending) {
    return <NovaFallback />;
  }

  if (!isMaintenance && restrictionCheckStatus === RequestStatus.Success) {
    return children;
  }

  const renderAnimation = () => (
    <div className='animation'>
      <i className='far spin-one fa-4x fa-cog' aria-hidden='true'></i>
      <i className='far spin-two fa-4x fa-cog fa-spin' aria-hidden='true'></i>
    </div>
  );

  return (
    <div className='nova-maintenance'>
      {renderAnimation()}
      <div className='nova-maintenance-text'>
        <h3>{_t('', 'MAINTENANCE.UNDERGOING_MAINTENANCE')}</h3>
        <p>{maintenanceMessage}</p>
      </div>
    </div>
  );
};

export default NovaMaintenance;
