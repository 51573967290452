import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import { transactionsRequestStatus, updateTransactions, updateTransactionsNextPageExists } from './transactions';
import TradeService from '#/api/trade/TradeService';
import { debounce, handleErrorThunk } from '#/util';
import { GetTransactionsPayload } from '#/api/trade/dto';
import { RequestStatus } from '#/types/enums';
import { AccountTransaction } from '../interfaces';
import { preparePagerDifferencePlus1, prepareResultPagerDifference } from '#/util/pager';
import { batch } from 'react-redux';

export const getTransactions = createAsyncThunk(
  'trade/getTransactions',
  debounce(1000, async (payload: GetTransactionsPayload, { dispatch, extra }: any) => {
    try {
      const { account_transactions }: { account_transactions: Array<AccountTransaction> } = await (
        extra.tradeService as TradeService
      ).getTransactions(preparePagerDifferencePlus1(payload));
      const pagerTransactions = prepareResultPagerDifference(account_transactions, payload.pager?.limit);
      batch(() => {
        dispatch(updateTransactions(pagerTransactions));
        dispatch(updateTransactionsNextPageExists(account_transactions.length > pagerTransactions.length));
      });
    } catch (error) {
      handleErrorThunk(error, 'Get transactions history failed', dispatch);
      dispatch(transactionsRequestStatus(RequestStatus.Failed));
    }
  }),
) as unknown as (payload: GetTransactionsPayload) => AnyAction;
