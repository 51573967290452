import BigNumber from 'bignumber.js';
import { BigNumberable } from '#/types';
import { formatNumberToLocale } from '../number';

export const formatAmountForRender = (amount: BigNumberable, decimalPlaces?: number) => {
  if (amount instanceof BigNumber) {
    return formatNumberToLocale(amount.toNumber(), decimalPlaces);
  }
  return formatNumberToLocale(Number(amount), decimalPlaces);
};

export const handleInputValueBeforeFormat = (_value: string) => {
  let value = _value;
  if ((value[0] === '.' && value.length > 1) || (value.length > 1 && value[0] === '0' && value[1] !== '.')) {
    value = value.substring(1, value.length);
  }

  return value;
};

export const formatInputValueToAllowedLength = (_value: string, maxInputLength = 8, maxPrecision = 8) => {
  const value = _value.replace('..', '.');
  let [integer, rest = null] = value.split('.');
  if (integer.length > maxInputLength) {
    integer = integer.substring(0, maxInputLength);
  }
  if (rest && rest.length > maxPrecision) {
    rest = rest.substring(0, maxPrecision);
  }

  return `${integer}${rest !== null ? '.' + rest : ''}`;
};

export const formatAmountBigLength = (amount: BigNumberable, maxLength: number, restLength: number = 2) => {
  const addAmountDivider = (amount: string) => {
    const integers = String(amount.split('.')[0]).length;
    const maxLengthFrom0 = integers + maxLength + 1;
    const arrayNumbers = String(amount).split('');
    const length = arrayNumbers.length;
    const firstPart = [...arrayNumbers].splice(0, maxLengthFrom0).join('');
    const secondPart = [...arrayNumbers].splice(maxLengthFrom0, length).join('');
    if (Number(amount) === 0 || length <= maxLength || firstPart.split('').some((number) => Number(number) > 0)) {
      return formatAmountForRender(firstPart);
    }
    let notZeroRest = '';
    secondPart.split('').forEach((number) => {
      if ((!notZeroRest.length && number !== '0') || (notZeroRest.length && notZeroRest.length < restLength)) {
        notZeroRest += number;
      }
    });
    return notZeroRest ? `${formatAmountForRender(firstPart)}...${notZeroRest}` : formatAmountForRender(firstPart);
  };
  if (amount instanceof BigNumber) {
    return addAmountDivider(amount.isGreaterThan(new BigNumber(0)) ? amount.toFixed(20) : '0');
  }
  return addAmountDivider(Number(amount) > 0 ? String(Number(amount).toFixed(20)) : String(amount));
};
