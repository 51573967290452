import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getUserProfile,
  selectGetUserStatus,
  selectUserUpdateStatus,
  selectUser,
  updateEmail as updateEmailAction,
  updateUser as updateUserAction,
  updateUserProfile,
  uploadDocument,
  selectUploadDocumentStatus,
  UploadDocumentResult,
  selectCryptoPayEnabled,
  IsActive,
  selectVerifyTwoFaRequest,
  verifyTwoFaToken,
  selectTwoFa,
  UserInfo,
  selectUserOptionNotifications,
  selectIsAuth,
  selectUserClientNotifications,
  deleteDocument,
  setTwoFa,
  updateVerifyTwoFa,
  selectTwoFaVefiryMessage,
  setTwoFaVefiryMessage,
  deleteUser,
} from '#reducers/user/user';
import { DocumentTypes, UpdateUserProfile } from '#/api/user/dto';
import { NotificationTrigger, NotificationsOptions, RequestStatus } from '#/types/enums';
import { getLimitsDisabledCurrenciesInstruments } from '#reducers/trade/paymentLimits';
import { selectIsUsing2fa } from '#reducers/settings';

const useUser = (): {
  isAuth: boolean;
  userInfo: UserInfo;
  userStatus: RequestStatus;
  userStatusLoading: boolean;
  userUpdateStatus: RequestStatus;
  documentUploadStatus: RequestStatus;
  mfaRequired: boolean;
  userUpdateStatusPending: boolean;
  documentUploadStatusPending: boolean;
  twoFaStatus: RequestStatus | 1;
  twoFa: string;
  notifications_settings: Array<NotificationTrigger>;
  cryptoPay: IsActive;
  notifications_options: NotificationsOptions | {};
  updateEmail: (email: string) => void;
  updateUser: (params: UserInfo | UpdateUserProfile) => void;
  updateProfilePhoto: (file: File, callback?: (src: string) => void) => void;
  deleteProfilePhoto: (callback?: (src: string) => void) => void;
  saveUser: (params?: UpdateUserProfile) => void;
  deleteUserAccount: () => void;
  checkTwoFa: () => void;
  updateTwoFaCode: (code: string) => void;
  updateTwoFaVerifyStatus: (status: RequestStatus) => void;
  updateTwoFaVerifyMessage: (message: string) => void;
  isUsingTwoFa: boolean;
  isTwoFaVerified: boolean;
  twoFaVerifyMessage: string;
} => {
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);
  const userInfo = useSelector(selectUser);
  const userStatus = useSelector(selectGetUserStatus);
  const userUpdateStatus = useSelector(selectUserUpdateStatus);
  const twoFaStatus = useSelector(selectVerifyTwoFaRequest);
  const twoFa = useSelector(selectTwoFa);
  const isUsingTwoFa = useSelector(selectIsUsing2fa);
  const isTwoFaVerified = twoFaStatus === RequestStatus.Success;
  const twoFaVerifyMessage = useSelector(selectTwoFaVefiryMessage);
  const documentUploadStatus = useSelector(selectUploadDocumentStatus);
  const cryptoPay = useSelector(selectCryptoPayEnabled);
  const notifications_settings = useSelector(selectUserClientNotifications);
  const notifications_options = useSelector(selectUserOptionNotifications);

  useEffect(() => {
    if (isAuth && !userInfo.username && !userInfo.email) {
      dispatch(getUserProfile());
      dispatch(getLimitsDisabledCurrenciesInstruments());
    }
  }, [isAuth]);

  const updateEmail = (email: string) => {
    dispatch(updateEmailAction(email));
  };

  const updateTwoFaCode = (code: string) => {
    dispatch(setTwoFa(code));
  };

  const updateTwoFaVerifyMessage = (message: string) => {
    dispatch(setTwoFaVefiryMessage(message));
  };

  const updateTwoFaVerifyStatus = (status: RequestStatus) => {
    dispatch(updateVerifyTwoFa(status));
  };

  const deleteUserAccount = () => {
    isUsingTwoFa ? (isTwoFaVerified ? dispatch(deleteUser()) : () => {}) : dispatch(deleteUser());
  };

  const updateProfilePhoto = (file: File, _callback?: (src: string) => void) => {
    dispatch(
      uploadDocument({
        payload: { file, documentType: DocumentTypes.ProfilePicture },
        callback: (result: UploadDocumentResult) => {
          updateUserState({
            ...userInfo,
            profile_pic_url: result.s3_public_url,
          });
          _callback?.(result.s3_public_url);
        },
      }),
    );
  };

  const deleteProfilePhoto = (_callback?: (src: string) => void) => {
    dispatch(
      deleteDocument({
        documentType: DocumentTypes.ProfilePicture,
        callback: (result: UploadDocumentResult) => {
          updateUserState({
            ...userInfo,
            profile_pic_url: result.s3_public_url,
          });
          _callback?.(result.s3_public_url);
        },
      }),
    );
  };

  const updateUserState = (user: UserInfo | UpdateUserProfile) => {
    dispatch(
      updateUserAction({
        ...userInfo,
        ...user,
      }),
    );
  };

  const saveUser = (user?: UpdateUserProfile) => {
    dispatch(
      updateUserProfile({
        ...userInfo, // Not all data comes from user var!
        ...(user || {}),
      }),
    );
  };

  const checkTwoFa = () => {
    dispatch(verifyTwoFaToken(twoFa));
  };

  return {
    isAuth,
    userInfo,
    userStatus,
    documentUploadStatus,
    documentUploadStatusPending: [RequestStatus.Pending].includes(documentUploadStatus),
    userStatusLoading: [RequestStatus.Pending, RequestStatus.None].includes(userStatus),
    userUpdateStatusPending: userUpdateStatus === RequestStatus.Pending,
    userUpdateStatus,
    notifications_settings,
    cryptoPay,
    notifications_options,
    mfaRequired: twoFaStatus === 1,
    twoFaStatus,
    twoFa,
    isUsingTwoFa,
    isTwoFaVerified,
    twoFaVerifyMessage,
    updateProfilePhoto: useCallback(updateProfilePhoto, []),
    deleteProfilePhoto: useCallback(deleteProfilePhoto, []),
    saveUser: useCallback(saveUser, [userInfo]),
    updateUser: useCallback(updateUserState, [userInfo]),
    updateEmail: useCallback(updateEmail, []),
    checkTwoFa: useCallback(checkTwoFa, [twoFa]),
    deleteUserAccount: useCallback(deleteUserAccount, [isTwoFaVerified]),
    updateTwoFaCode: useCallback(updateTwoFaCode, []),
    updateTwoFaVerifyStatus: useCallback(updateTwoFaVerifyStatus, []),
    updateTwoFaVerifyMessage: useCallback(updateTwoFaVerifyMessage, []),
  };
};

export default useUser;
