/**
 * @name capitalize
 *
 * @description Convert first letter of each words to uppercase
 *
 * @param {String} str
 * @returns {String}
 */

const capitalize = (str: string, forceLowercase: boolean = false) => {
  let capStr = str;
  if (forceLowercase) {
    capStr = str.toLowerCase();
  }
  return capStr.replace(/\w\S*/g, (_) => _.charAt(0).toUpperCase() + _.substr(1));
};
export default capitalize;
