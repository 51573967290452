import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { throttle, handleErrorThunk } from '#/util';
import {
  updatePortfolioHistory,
  updatePortfolioHistoryRequestStatus,
  updatePortfolio24RequestStatus,
  updatePortfolio24h,
} from './portfolio-history';
import { RequestStatus } from '#/types';
import { PortfolioChartTimeframes } from './timeframes';
import TradeService from '#/api/trade/TradeService';
import configEnv from '#/config/config-env';

interface GetPortfolioHistoryParams {
  timeframe: PortfolioChartTimeframes;
  currency: string;
}
export const getPortfolioHistory = createAsyncThunk(
  'home/getPortfolioHistory',
  throttle(async (params: GetPortfolioHistoryParams, { dispatch, extra }: any) => {
    try {
      dispatch(updatePortfolioHistoryRequestStatus(RequestStatus.Pending));
      const { portfolio_history } = await (extra.tradeService as TradeService).getPortfolioHistory({
        currency_id: params.currency,
        interval: params.timeframe,
      });

      dispatch(
        updatePortfolioHistory(
          portfolio_history.map((_: any) => ({
            balanceInBaseCurrency: _[1],
            timestamp: _[0],
          })),
        ),
      );
    } catch (error) {
      handleErrorThunk(error, _t('Get portfolio history failed', 'TOAST.ERROR_PORTFOLIO_HISTORY'), dispatch);
      dispatch(updatePortfolioHistoryRequestStatus(RequestStatus.Failed));
      dispatch(updatePortfolioHistory([{ balanceInBaseCurrency: 0, timestamp: Date.now() }]));
    }
  }, 500),
) as unknown as (params: GetPortfolioHistoryParams) => AnyAction;

interface GetPortfolioHistory24hParams {
  currency: string;
}
export const getPortfolioHistory24h = createAsyncThunk(
  'home/getPortfolioHistory24h',
  throttle(async (params: GetPortfolioHistory24hParams, { dispatch, extra }: any) => {
    try {
      dispatch(updatePortfolio24RequestStatus(RequestStatus.Pending));
      const { portfolio_history } = await (extra.tradeService as TradeService).getPortfolioHistory({
        currency_id: params.currency,
        interval: PortfolioChartTimeframes.Day,
      });

      dispatch(
        updatePortfolio24h(
          portfolio_history.map((_: any) => ({
            balanceInBaseCurrency: _[1],
            timestamp: _[0],
          })),
        ),
      );
    } catch (error) {
      handleErrorThunk(error, _t('Get portfolio 24h failed', 'TOAST.ERROR_PORTFOLIO_HISTORY'), dispatch);
      dispatch(updatePortfolio24RequestStatus(RequestStatus.Failed));
    }
  }, 500),
) as unknown as (params: GetPortfolioHistory24hParams) => AnyAction;
