import { lowercaseObjectKeys } from '#/util';

export function getUrlParams(ignoreCase: boolean = false): {
  [key: string]: string;
} {
  const urlSearchParams = new URLSearchParams(window.location.search);
  // @ts-ignore
  let params = Object.fromEntries(urlSearchParams.entries());

  if (ignoreCase) {
    params = lowercaseObjectKeys(params);
  }

  return params;
}

export default getUrlParams;
