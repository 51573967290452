import { RequestStatus } from '#/types/enums';
import kyc from '#/config/kyc';
import {
  UserAccountTypes,
  KycProviders,
  KycProvidersSettings,
  KycProvidersEnabled,
  KycWidgetsUrl,
  UserKycStatus,
} from './types';
import { UserKycDoc } from '#reducers/user/user';

export enum VerificationModalType {
  No = 'No',
  Both = 'Both',
  Kyc = 'Kyc',
  TwoFa = 'TwoFa',
}

export enum KycType {
  Company = 'company',
  Individual = 'individual',
}

export interface IKycInfoFields {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  address_line_1: string;
  address_line_2: string;
  kyc_status: UserKycStatus;
  kyc_level: string;
  kyc_message: string;
  kyc_type: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
  nationality: string;
  tax_id: string;
  account_opening_purpose: string;
  company_registration_nr: string;
  company_name: string;
  company_position: string;
  mobile_nr: string;
  unique_id: string;
  affiliate_code: string;
}

export interface KycGetData {
  user_id: string;
  kyc_property: string;
  kyc_value: string;
  updated_at: string;
}

export const getUserKycInfoFields = [
  'first_name',
  'last_name',
  'date_of_birth',
  'address_line_1',
  'address_line_2',
  'kyc_status',
  'kyc_level',
  'kyc_message',
  'kyc_type',
  'address_city',
  'address_state',
  'address_zip',
  'address_country',
  'nationality',
  'tax_id',
  'account_opening_purpose',
  'company_registration_nr',
  'company_name',
  'company_position',
  'mobile_nr',
  'unique_id',
  'affiliate_code',
];

export interface IKycState {
  accountType?: UserAccountTypes;
  kycData: IKycInfoFields | null;
  kycDoc: UserKycDoc | null;
  statusKycPreference: RequestStatus;
  kycSessionId: string;
  verificationModalType: VerificationModalType;
  submitManualKycRequest: RequestStatus;
  kycProviders: KycProvidersSettings | null;
  kycProvidersEnabled: KycProvidersEnabled | null;
  kycWidgets: KycWidgetsUrl;
  isSeveralOptionVerify: boolean;
}

export const initialKycState: IKycState = {
  accountType: undefined,
  kycProviders: null,
  kycProvidersEnabled: null,
  kycWidgets: {
    individual: '',
    corporate: '',
  },
  kycData: null,
  kycDoc: null,
  statusKycPreference: RequestStatus.None,
  kycSessionId: '',
  verificationModalType: VerificationModalType.No,
  submitManualKycRequest: RequestStatus.None,
  isSeveralOptionVerify: false,
};
