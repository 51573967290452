import { User, UserInfo, UserMarketCurrencies, UserLanguages, IKycGetResponse, BuildKycData } from './types';
import authHelper from './auth-helper';
import { getTimeZone } from '#/util';
import { RequestStatus } from '#/types/enums';
import { IsActive } from '#/types';
import { IKycInfoFields, UserKycStatus } from '#reducers/kyc';
import { IKycData } from '#/api/settings/dto';
import { getTimZoneAbrr } from '#/util/date/getTimeZoneAbrr';
import { localStorageTimeZoneKey } from '#/util/date/getTimezone';

export const userDefaultKycInfo: IKycInfoFields = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
  address_line_1: '',
  address_line_2: '',
  kyc_status: '' as UserKycStatus,
  kyc_level: '',
  kyc_message: '',
  kyc_type: '',
  address_city: '',
  address_state: '',
  address_zip: '',
  address_country: '',
  nationality: '',
  tax_id: '',
  account_opening_purpose: '',
  company_registration_nr: '',
  company_name: '',
  company_position: '',
  mobile_nr: '',
  unique_id: '',
  affiliate_code: '',
};

export const defaultUserInfo: UserInfo = {
  username: '',
  language: UserLanguages.English,
  timezone: getTimeZone(),
  email: '',
  crypto_pay: IsActive.Off,
  favorite_instruments: [],
  favorite_fiat_destinations: [],
  mfa_status: IsActive.Off,
  favorite_addresses_crypto: [],
  primary_market_currency: UserMarketCurrencies.USD,
  profile_pic_url: undefined, // path to image
  kyc_data: [
    {
      kyc_property: '',
      kyc_value: '',
    },
  ],
  userKycInfo: { ...userDefaultKycInfo },
  user_id: '',
  notifications_options: {},
  userCounrty: '',
  default_timezone: IsActive.On,
  anti_phishing_code: '',
  fee_group_id: '',
};

export const initialStateUser: User = {
  userInfo: defaultUserInfo,
  uploadDocumentStatus: RequestStatus.None,
  verifyTwoFaRequest: RequestStatus.None,
  getUserStatus: RequestStatus.None,
  updateUserStatus: RequestStatus.None,
  changePasswordStatus: RequestStatus.None,
  userEventSubscribers: {},
  permissions: [],
  twoFa: '',
  twoFaVerifyMessage: '',
  isInactive: false,
  refreshToken: '',
  isTokenRefreshing: false,
  authErrorMessage: '',
  changePasswordError: '',
  updateAntiPhishingStatus: RequestStatus.None,
  ...authHelper.getAuthFromStorage(),
};

export const localStorageKycLastView = 'verification-reminder';

export const constructKycData = (data: IKycGetResponse[]): IKycInfoFields =>
  data.reduce((kycItem, kycData) => {
    const kycFields = {
      ...kycItem,
      [kycData[BuildKycData.Kyc_property] as string]: kycData[BuildKycData.Kyc_value],
    };
    return kycFields;
  }, {}) as IKycInfoFields;

export const buildUpdateKycData = (data: { [key: string]: string }): IKycData[] => {
  return Object.entries(data).map(([key, value]) => ({
    [BuildKycData.Kyc_property]: key,
    [BuildKycData.Kyc_value]: value,
  }));
};

export const setStorageTimeZone = (isAutoTimeZone: IsActive | undefined, userTimezone: string) => {
  localStorage.setItem(
    localStorageTimeZoneKey,
    JSON.stringify(
      isAutoTimeZone === IsActive.Off
        ? { timeZone: userTimezone, isAutoTimezone: false }
        : { timeZone: getTimZoneAbrr(), isAutoTimezone: true },
    ),
  );
};
