/**
 * @name toAllCaps
 *
 * @description Convert all letters to uppercase replace spaces and slahes with dash
 *
 * @param {String} str
 * @returns {String}
 */

const toAllCaps = (str: string) => str.replace(/[ /]/g, '_').toUpperCase();

export default toAllCaps;
