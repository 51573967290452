export const isEmail = (value: string) => {
  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  if (emailRegex.test(value)) {
    return true;
  }
  return false;
};

export const isUsername = (value: string) => {
  const usernameRegex = /[\p{L}\p{M}\p{S}\p{N}\p{P}]+/u;

  if (usernameRegex.test(value)) {
    return true;
  }
  return false;
};
