import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import UserService from '#/api/user/UserService';
import { RequestStatus } from '#/types/enums';
import { throttle, handleErrorThunk } from '#/util';

import { IGetUserPaymentFeesPayload } from '#/api/user/dto';
import { updatePaymentFees, updatePaymentFeesRequestStatus } from './paymentFees';

export const getPaymentFees = createAsyncThunk(
  'trade/getPaymentFees',
  throttle(async (payload: IGetUserPaymentFeesPayload, { dispatch, extra }: any) => {
    dispatch(updatePaymentFeesRequestStatus(RequestStatus.Pending));
    try {
      const { payments_fees } = await (extra.userService as UserService).getUserPaymentFees(payload);
      dispatch(updatePaymentFees(payments_fees));
    } catch (error) {
      dispatch(updatePaymentFeesRequestStatus(RequestStatus.Failed));
      handleErrorThunk(error, 'Get payment Fees failed', dispatch);
    }
  }, 1000),
) as unknown as (payload: IGetUserPaymentFeesPayload) => AnyAction;
