import { NotificationsOptions, RequestStatus } from '#/types/enums';

export interface SettingsState {
  maintenanceMode: boolean;
  maintenanceMessage: string;
  mfaStatusRequest: RequestStatus;
  is_using_2fa: boolean;
  secretCode: string;
  secretCodeUri: string;
  updateStatusRequest: RequestStatus;
  isDevelopment: boolean;
  enabledNotifications: NotificationsOptions | {};
  geo_restrictions: boolean;
  isIpBlacklisting: boolean;
  isGeoBlock: boolean;
  restrictionCheckStatusRequest: RequestStatus;
}

export enum SsoAuthTypes {
  Cognito = 'cognito',
}

export interface SsoSettingsItem {
  domain: string | undefined;
  client_id: string | undefined;
  type: SsoAuthTypes | '' | undefined;
  pool_id: string | undefined;
}

export interface INotificationSettings {
  notification_settings: NotificationsOptions | {};
}
