export const vaultProductFields = [
  // TODO
  // 'productId',
  'id',
  'name',
  'description',
  'companyId',
  'assetId',
  'totalBalance',
  'tags',
  'enabled',
  'allocationEnabled',
  'minimumAllocationAmount',
  'maximumAllocationAmount',
  'redemptionEnabled',
  'minimumRedemptionAmount',
  'maximumRedemptionAmount',
  'termType',
  'termUnit',
  'baseAnnualRate',
  'termLength',
  {
    interest: [
      'accrualMode',
      'accrualMethod',
      'accrualIntervalUnit',
      'accrualInterval',
      'rateType',
      // 'lockUps',
    ],
  },
  // 'customData',
  'protocol',
  'categories',
  {
    farmingAssets: ['id', { details: ['account', 'protocol' /*'rewardAPY'*/] }],
  },
  'createdAt',
  'updatedAt',
  'dailyThresholdDelay',
  'dailyThresholdRedemptionLimit',
  'counting',
  { tiers: ['tierFrom', 'tierTo', 'apy', 'apr'] },
];

export const vaultHistoryFields = [
  'id',
  'parentId',
  'sequenceId',
  // 'lendingId',
  'accountTypeId',
  'userId',
  'productId',
  'assetId',
  'amount',
  'comments',
  'transactionType',
  'companyId',
  'detailsId',
  'createdAt',
  'updatedAt',
  // 'status',
  {
    externalMetadata: ['accrualEndingReference', 'accrualStartingReference'],
  },
];

export const vaultPendingTransactionsFields = [
  'companyId',
  'userId',
  'productId',
  'assetId',
  'amount',
  'comments',
  // 'createdAt', // TODO
  // 'updatedAt',
  'id',
  'transactionType',
  'status',
  'reason',
];

export const vaultBalancesFields = [
  'companyId',
  'createdAt',
  'updatedAt',
  'description',
  'sequenceId',
  'id',
  'userId',
  'productId',
  'assetId',
  'accountType',
  'balance',
  'accountingClass',
];

export const vaultMakeWithdrawFields = [
  'companyId',
  'userId',
  'productId',
  'assetId',
  'transactionType',
  'amount',
  'comments',
  'createdAt',
  'updatedAt',
  'product',
  'id',
  'accountTypeId',
  'parentId',
  'sequenceId',
  'detailsId',
  'externalMetadata',
];
