export const healthCheckFields = ['maintenance_message', 'maintenance_mode'];

const ssoSettingsItemFields = ['domain', 'client_id', 'type', 'pool_id'];

export const getSsoSettingsFields = [
  {
    trader: ssoSettingsItemFields,
  },
];

export const getCountryFields = ['name', 'code', 'phone_code'];

export const getProvinceFields = ['country_code', 'province_code', 'iso_region', 'full_name', 'short_name', 'Type'];

export const getNotificationSettingsFields = ['client', 'push', 'email', 'sms'];

export const geoRestrictionsFields = [
  {
    region_blacklist: [
      'code',
      {
        list: ['code', 'name'],
      },
    ],
  },
  {
    country_blacklist: ['code', 'name'],
  },
  {
    current_geo: ['country'],
  },
];
