import * as gql from 'gql-query-builder';
import { GetTransactionsPayload, GetPortfolioHistoryParams } from './dto';
import { subscribeTradeEventsFields, getBalancesFiels, getTransactionsFields } from './fields';
import { toAllCaps } from '#/util';
export * from './dto';

import orders from './trade-qql/orders';
import depositWithdraw from './trade-qql/depositWithdraw';
import conversion from './trade-qql/conversion';
import payments from './trade-qql/payments';
import instruments from './trade-qql/instruments';

export enum TradeOperations {
  GetTransactions = 'account_transactions',
  UserUpdate = 'user_update',
  Balances = 'accounts_balances',
  GetPortfolioHistory = 'portfolio_history',
}

export enum AliasTradeOperations {
  Balances = 'alias_get_accounts_balances',
}

export default {
  ...orders,
  ...depositWithdraw,
  ...conversion,
  ...payments,
  ...instruments,

  getPortfolioHistory: (params: GetPortfolioHistoryParams) => {
    return gql.query({
      operation: TradeOperations.GetPortfolioHistory,
      variables: {
        currency_id: {
          value: params.currency_id,
          required: true,
          type: 'String',
        },
        timestamp: {
          value: params.timestamp || String(Date.now()),
          required: true,
          type: 'String',
        },
        interval: {
          value: params.interval,
          required: true,
          type: 'PortfolioHistoryInterval',
        },
      },
      fields: [],
    });
  },

  getTransactions: (params: GetTransactionsPayload) => {
    return gql.query({
      operation: TradeOperations.GetTransactions,
      variables: {
        currency_id: {
          value: toAllCaps(String(params.currency_id)),
          required: false,
          type: 'String',
        },
        pager: { value: params.pager, required: false, type: 'PagerInput' },
        dateRange: {
          value: params.dateRange,
          required: false,
          type: 'DateRangeInput',
        },
      },
      fields: getTransactionsFields,
    });
  },

  subscribeTradeEvents: () => {
    return gql.subscription({
      operation: TradeOperations.UserUpdate,
      variables: {},
      fields: subscribeTradeEventsFields,
    });
  },

  getBalances: () => {
    return gql.query({
      operation: {
        name: TradeOperations.Balances,
        alias: AliasTradeOperations.Balances,
      },
      fields: getBalancesFiels,
    });
  },
};
