import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import NovaThemeProvider from './nova/theme/NovaThemeProvider';
import vaults from './config/vaults';
import { Themes, ToastThemes } from './types/enums';
import useTheme from '#/hooks/config/useTheme';
import nova from './nova';
import Router from './router/Router';
import NovaMaintenance from './nova/hoc/nova-maintenance/NovaMaintenance';
import NovaWithTranslation from './nova/hoc/nova-with-translation/NovaWithTranslation';
import { defaultStorageValueChartHistory } from './util/chart/defaultStorageValue';
import NovaSubscriptions from '#/nova/hoc/nova-subscriptions/NovaSubscriptions';
import useDevelopmentMode from './hooks/settings/useDevelopmentMode';
import { useUserActivityEventListener } from './hooks/helpers/useUserActivityEventListener';
import { getInstruments } from '#reducers/trade';
import { selectIsAuth } from '#reducers/user/user';

import 'react-datepicker/dist/react-datepicker.css';

function initCssVarsForObject(object: any, aliasKey: string) {
  Object.entries(object).forEach(([key, value]) => {
    document.body.style.setProperty(`--${aliasKey}-${key}`, String(value));
  });
}

function initChartConfigStorage() {
  const key = 'tradingview.chartproperties.mainSeriesProperties';
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, JSON.stringify(defaultStorageValueChartHistory));
  }
}

function App() {
  const { theme } = useTheme();
  initChartConfigStorage();
  useDevelopmentMode();
  useUserActivityEventListener();
  const dispatch = useDispatch();
  const isAuth = useSelector(selectIsAuth);

  useEffect(() => {
    dispatch(getInstruments());
  }, [isAuth]);

  useEffect(() => {
    initCssVarsForObject(vaults.vaults.colors, 'vaults-currencies-colors');
    initCssVarsForObject(vaults.vaults.colorsRgb, 'vaults-currencies-colors-rgb');
  }, []);

  return (
    <NovaWithTranslation>
      <NovaThemeProvider theme={theme}>
        <NovaMaintenance>
          <>
            <NovaSubscriptions />
            <Router components={{ ...nova }} />
          </>
        </NovaMaintenance>
        <ToastContainer
          hideProgressBar
          autoClose={7000}
          theme={theme === Themes.Dark ? ToastThemes.Dark : ToastThemes.Light}
        />
      </NovaThemeProvider>
    </NovaWithTranslation>
  );
}

export default App;
