import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';

import { novaToast } from '#/nova/components/other/toast/novaToast';
import { GqlClient } from '#/api/gql';
import {
  ChangePasswordPayload,
  DocumentTypes,
  IAntiPhishingCodePayload,
  IGetUserProfilePayload,
  ILoginResult,
  LoginPayload,
  UpdateUserProfile,
  UploadDocument,
} from '#/api/user/user-gql';
import { RequestStatus } from '#/types/enums';
import UserService from '#/api/user/UserService';
import { AuthOptions, debugCognitoActive } from '#/config/auth';
import { selectConfigAuthType, setLanguage } from '#reducers/config';
import TradeService from '#/api/trade/TradeService';
import { ISelectedInstrument, UserEventResult } from '#reducers/trade/interfaces';
import { throttle, handleErrorThunk, compareStrings, handleChangePasswordError, getTimeZone } from '#/util';
import { Store } from '#/store/defaultState';
import { IKycGetResponse, SubscriberUserEvent, UploadDocumentResult, UserLanguages } from './types';
import { defaultUserInfo, localStorageKycLastView, setStorageTimeZone } from '#reducers/user/user';
import authHelper, { AuthStorageItems, getRefreshInfo } from './auth-helper';
import {
  updateAuth,
  updateAuthStatus,
  getUserStatus,
  uploadDocumentStatus,
  updateUser,
  updateUserStatus,
  _selectSubscribersArray,
  updatePermissions,
  selectAuthStatus,
  updateVerifyTwoFa,
  setTwoFa,
  selectUserClientNotifications,
  updateTokenRefreshing,
  selectTokenExpires,
  selectRefreshToken,
  updateChangePasswordStatus,
  updateChangePasswordError,
  setTwoFaVefiryMessage,
  updateAntiPhishingStatus,
} from './user';
import { eventNotificationToast, eventOrderToast, eventPaymentToast, executeConversionToast } from './toasts';
import { getBalances } from '#reducers/trade/balances/thunks';
import { updateMaintenanceMessage, updateMaintenanceMode } from '#reducers/settings';
import { removeStorage } from '#/aws-amplify.setup';
import { Languages } from '#/config/language/langConfig';
import { localStorageLangKey } from '#/util/translation/getCurrentLang';
import { selectInstrumentsArray } from '#reducers/trade';
import { Paths } from '#/config/templates/nova/nova';

enum HubEvents {
  SignIn = 'signIn',
  AuthChannel = 'auth',
  SignInListener = 'signin',
  SignOut = 'signOut',
  OAuthSignOut = 'oAuthSignOut',
  TokenRefresh = 'tokenRefresh',
}

export const logoutActionName = 'user/logout';
export const logout = createAsyncThunk(
  logoutActionName,
  throttle(async (_: any, { dispatch, extra }: any) => {
    try {
      setTimeout(() => {
        authHelper.setAuthToStorage({ token: '', expires_at: '' });
        localStorage.removeItem(localStorageKycLastView);
        (extra.gqlRequestClientVako as GqlClient).setToken(undefined);
        (extra.gqlRequestClientLending as GqlClient).setToken(undefined);
        (extra.gqlRequestClientVakoIdToken as GqlClient).setToken(undefined);
        (extra.tradeService as TradeService).reconnectWithAuthorization(undefined);
        dispatch(
          updateAuth({
            authStatus: RequestStatus.None,
            expires_at: '',
            token: '',
          }),
        );
        dispatch(updateUser(defaultUserInfo));
        removeStorage();
        location.replace(Paths.Landing);
      }, 300);
      novaToast.info(_t('You were logged out', 'LOGOUT.LOGOUT'));
    } catch (error) {
      handleErrorThunk(error, 'Logout failed', dispatch);
    } finally {
      setTimeout(() => {
        Auth.signOut({ global: true });
        console.log('sign out aws');
      }, 200);
    }
  }, 1500),
) as unknown as () => AnyAction;

export const getUserProfile = createAsyncThunk(
  'user/getProfile',
  throttle(async (params: IGetUserProfilePayload, { dispatch, extra }: any) => {
    dispatch(getUserStatus(RequestStatus.Pending));
    try {
      const [{ user }, { permissions }] = await Promise.all([
        (extra.userService as UserService).getUserProfile(params),
        (extra.userService as UserService).getPermissions(),
      ]);

      const language = user.language === UserLanguages.English ? Languages.En : user.language;
      batch(() => {
        if (language) {
          dispatch(setLanguage(language as Languages));
          localStorage.setItem(localStorageLangKey, language);
        }
        setStorageTimeZone(user.default_timezone, user.timezone);
        dispatch(getUserStatus(RequestStatus.Success));
        dispatch(updateUser(user));
        dispatch(updatePermissions(permissions));
      });
    } catch (error) {
      setTimeout(() => handleErrorThunk(error, 'Get user profile failed', dispatch), 1000);
      // does not log out when 2fa code is sent because this code is sent only if the user is already logged in and wants to request hidden values
      if (!params?.twoFaCode) {
        dispatch(logout());
      }
      dispatch(getUserStatus(RequestStatus.Failed));
    }
  }, 1500),
) as unknown as (params?: IGetUserProfilePayload) => AnyAction;

interface UploadDocumentWithCallback {
  payload: UploadDocument;
  callback?: (result: UploadDocumentResult) => void;
}
export const uploadDocument = createAsyncThunk(
  'user/uploadDocument',
  async ({ payload, callback }: UploadDocumentWithCallback, { dispatch, extra }: any) => {
    dispatch(uploadDocumentStatus(RequestStatus.Pending));
    try {
      const { ok, result } = await (extra.userService as UserService).uploadDocument(payload);
      if (ok) {
        callback?.(result as UploadDocumentResult);
        dispatch(uploadDocumentStatus(RequestStatus.Success));
        novaToast.success(_t('Profile Updated', 'TOAST.SUCCESS_UPLOAD'));
      } else {
        novaToast.error('Uploading failed!');
        dispatch(uploadDocumentStatus(RequestStatus.Failed));
      }
    } catch (error) {
      handleErrorThunk(error, 'Document upload failed', dispatch);
      dispatch(uploadDocumentStatus(RequestStatus.Failed));
    }
  },
) as unknown as (payload: UploadDocumentWithCallback) => AnyAction;

interface DeleteDocumentWithCallback {
  documentType: DocumentTypes;
  callback?: (result: UploadDocumentResult) => void;
}
export const deleteDocument = createAsyncThunk(
  'user/uploadDocument',
  async ({ documentType, callback }: DeleteDocumentWithCallback, { dispatch, extra }: any) => {
    dispatch(uploadDocumentStatus(RequestStatus.Pending));
    try {
      const { ok, result } = await (extra.userService as UserService).deleteDocument(documentType);
      if (ok) {
        callback?.(result as UploadDocumentResult);
        dispatch(uploadDocumentStatus(RequestStatus.Success));
        novaToast.success(_t('Profile Updated', 'TOAST.SUCCESS_UPLOAD'));
      } else {
        novaToast.error(_t('Deleting failed', 'TOAST.DELETING_FAILED'));
        dispatch(uploadDocumentStatus(RequestStatus.Failed));
      }
    } catch (error) {
      handleErrorThunk(error, _t('Document deleting failed', 'TOAST.DOCUMENT_DELETING_FAILED'), dispatch);
      dispatch(uploadDocumentStatus(RequestStatus.Failed));
    }
  },
) as unknown as (payload: DeleteDocumentWithCallback) => AnyAction;

export const updateUserProfile = createAsyncThunk(
  'user/updateProfile',
  async (_user: UpdateUserProfile, { dispatch, extra }: any) => {
    if (!_user.ignorePending) {
      // For instruments - ignore loading spinner
      dispatch(updateUserStatus(RequestStatus.Pending));
    }
    try {
      const { update_user } = await (extra.userService as UserService).updateUserProfile({
        email: _user.email || undefined,
        favorite_instruments: _user.favorite_instruments || undefined,
        favorite_fiat_destinations: _user.favorite_fiat_destinations || undefined,
        favorite_addresses_crypto: _user.favorite_addresses_crypto || undefined,
        language: _user.language || undefined,
        primary_market_currency: _user.primary_market_currency || undefined,
        timezone: _user.timezone || undefined,
        username: _user.username || undefined,
        notifications_options: _user.notifications_options || undefined,
        default_timezone: _user.default_timezone || undefined,
      });
      batch(() => {
        dispatch(updateUserStatus(RequestStatus.Success));
        dispatch(updateUser(update_user));
        if (_user.updateBalance) {
          dispatch(
            getBalances({
              marketCurrency: update_user.primary_market_currency,
              isShowLoader: true,
            }),
          );
        }
        if (!_user.ignoreNotification) {
          const notification =
            _user.customNotification || _t('Successfully updated user!', 'TOAST.SUCCESS_UPLOAD_USER');
          novaToast.success(notification);
        }
        setStorageTimeZone(update_user.default_timezone, update_user.timezone);
      });
    } catch (error) {
      handleErrorThunk(error, 'Update profile failed', dispatch);
      dispatch(updateUserStatus(RequestStatus.Failed));
    }
  },
) as unknown as (user: UpdateUserProfile) => AnyAction;

export const updateUserLanguage = createAsyncThunk(
  'user/updateProfile',
  async (language: UserLanguages, { dispatch, extra }: any) => {
    try {
      await (extra.userService as UserService).updateUserProfile({
        language: language || undefined,
      });
    } catch (error) {
      dispatch(updateUserStatus(RequestStatus.Failed));
    }
  },
) as unknown as (language: Languages) => AnyAction;

export const updateAntiPhishingCode = createAsyncThunk(
  'user/updateAntiPhishingCode',
  async (payload: IAntiPhishingCodePayload, { dispatch, extra }: any) => {
    dispatch(updateAntiPhishingStatus(RequestStatus.Pending));
    try {
      await (extra.userService as UserService).updateAntiPhishingCode(payload);
      dispatch(updateAntiPhishingStatus(RequestStatus.Success));
      novaToast.success(_t('Successfully updated user!', 'TOAST.SUCCESS_UPLOAD_USER'));
    } catch (error) {
      handleErrorThunk(error, 'Update profile failed', dispatch);
      dispatch(updateAntiPhishingStatus(RequestStatus.Failed));
    }
  },
) as unknown as (language: IAntiPhishingCodePayload) => AnyAction;

interface MakeSigniIn extends ILoginResult {
  checkIn: boolean;
  refreshToken?: string;
  idToken?: string;
}

export const makeSignIn = createAsyncThunk(
  'user/makeSignIn',
  async ({ expires_at, jwt, checkIn, refreshToken, idToken }: MakeSigniIn, { dispatch, extra }: any) => {
    try {
      batch(() => {
        const token = `Bearer ${jwt}`;
        const tokenid = `Bearer ${idToken}`;

        dispatch(
          updateAuth({
            token,
            expires_at: String(expires_at),
            authStatus: RequestStatus.Success,
            refreshToken: refreshToken ?? '',
          }),
        );
        (extra.gqlRequestClientVako as GqlClient).setToken(token);
        (extra.gqlRequestClientLending as GqlClient).setToken(token);
        (extra.gqlRequestClientVakoIdToken as GqlClient).setToken(tokenid);
        (extra.tradeService as TradeService).reconnectWithAuthorization(token);
      });
      if (checkIn && idToken) {
        await (extra.userService as UserService).checkIn();
      }
    } catch (error) {
      setTimeout(() => handleErrorThunk(error, 'Check in failed', dispatch), 1000);
    }
  },
) as unknown as (tokenParams: MakeSigniIn) => AnyAction;

export const verifyTwoFaToken = createAsyncThunk(
  'user/verifyTwoFaToken',
  throttle(async (token: string, { dispatch, extra }: any) => {
    try {
      const { verify_user_mfa_token: verified } = await (extra.userService as UserService).verifyTwoFaToken(token);
      if (verified) {
        batch(() => {
          dispatch(updateVerifyTwoFa(RequestStatus.Success));
          dispatch(setTwoFa(''));
          dispatch(setTwoFaVefiryMessage('Verification Successful'));
        });
        return null;
      }
      dispatch(setTwoFaVefiryMessage('2 FA wrong code'));
      return novaToast.error('2 FA wrong code');
    } catch (error) {
      handleErrorThunk(error, '2 FA request failed', dispatch);
    }
  }, 300),
) as unknown as (token: string) => AnyAction;

export const login = createAsyncThunk(
  'user/login',
  async (params: LoginPayload, { dispatch, getState, extra }: any) => {
    dispatch(updateAuthStatus(RequestStatus.Pending));
    try {
      const authConfigType = selectConfigAuthType(getState());
      if (!authConfigType) {
        throw new Error('Authentication type has not specified');
      }
      const service = extra.userService as UserService;
      const request = {
        [AuthOptions.Vako]: (params: LoginPayload) => service.login(params),
      };
      const { trader_demo_signin } = await (request as any)[authConfigType](params);
      dispatch(
        makeSignIn({
          ...trader_demo_signin,
          checkIn: [AuthOptions.Cognito].includes(authConfigType),
        }),
      );
    } catch (error) {
      handleErrorThunk(error, 'Login failed', dispatch);
      dispatch(updateAuthStatus(RequestStatus.Failed));
    }
  },
) as unknown as (params: LoginPayload) => AnyAction;

const debugCognito = (...params: any) => (debugCognitoActive ? console.log(...params) : {});

export const subscribeCognitoAuth = createAsyncThunk(
  'user/subscribeAuthCognito',
  async (_: undefined, { dispatch, getState }) => {
    try {
      const isAuth = selectAuthStatus(getState() as Store) === RequestStatus.Success;

      const signIn = (googleUser: any, checkIn: boolean = true) => {
        if (googleUser && googleUser?.signInUserSession) {
          const api_token = googleUser?.signInUserSession?.accessToken?.jwtToken;
          const expires_in = googleUser?.signInUserSession?.accessToken?.payload?.exp;
          const refreshToken = googleUser?.signInUserSession?.refreshToken.token;
          const idToken = googleUser?.signInUserSession?.idToken.jwtToken;

          dispatch(
            makeSignIn({
              expires_at: expires_in * 1000,
              jwt: api_token,
              refreshToken,
              idToken,
              checkIn: checkIn && !isAuth,
            }),
          );
        }
      };

      Auth.currentAuthenticatedUser()
        .then((googleUser: any) => {
          signIn(googleUser, true);
        })
        .catch((e) => {
          if (isAuth) {
            console.error('Cognito not authenticated', e);
          }
        });

      // Auth.wrapRefreshSessionCallback((data) => debugCognito('aws refresh callback', data));

      Hub.listen(
        HubEvents.AuthChannel,
        async ({ payload }) => {
          debugCognito(payload);
          switch (payload.event) {
            case HubEvents.SignIn:
              signIn(payload.data);
              break;
            case HubEvents.SignOut:
            case HubEvents.OAuthSignOut:
              dispatch(logout());
              break;
            default:
              break;
          }
        },
        HubEvents.SignInListener,
      );
    } catch (error) {
      handleErrorThunk(error, 'Subscribe cognito authentication failed', dispatch);
    }
  },
) as unknown as () => AnyAction;

export const subscribeUserEvents = createAsyncThunk(
  'user/subscribeUserEvents',
  async (_: undefined, { dispatch, extra, getState }: any) => {
    try {
      (extra.tradeService as TradeService).subscribeUserEvents({
        next: ({ data: { user_update } = { user_update: {} } as any }: { data: { user_update: UserEventResult } }) => {
          const state = getState();
          _selectSubscribersArray(state).forEach((_: SubscriberUserEvent) => _?.callback(user_update, dispatch));
          const notifications_settings = selectUserClientNotifications(state);
          const instrumentsArray: ISelectedInstrument[] = selectInstrumentsArray(state);
          if (user_update.order) {
            eventOrderToast(user_update.order, notifications_settings, instrumentsArray);
          }
          if (user_update.payment) {
            eventPaymentToast(user_update.payment, notifications_settings);
          }
          if (user_update.conversion) {
            executeConversionToast(user_update.conversion, notifications_settings);
          }
          if (user_update.notification) {
            eventNotificationToast(user_update.notification);
          }
          if (user_update.healthcheck) {
            batch(() => {
              dispatch(updateMaintenanceMessage(user_update.healthcheck?.maintenance_message || ''));
              dispatch(updateMaintenanceMode(user_update.healthcheck?.maintenance_mode || false));
            });
          }
        },
        error: (error: any) => {
          handleErrorThunk(error, 'Error on user events subscription', dispatch);
        },
        complete: () => {},
      });
    } catch (error) {
      handleErrorThunk(error, 'Subscribe user events failed', dispatch);
    }
  },
) as unknown as () => AnyAction;

export const unsubscribeUserEvents = createAsyncThunk(
  'user/unsubscribeUserEvents',
  async (_: undefined, { extra, dispatch }: any) => {
    try {
      (extra.tradeService as TradeService).unsubscribeUserEvents();
    } catch (error) {
      handleErrorThunk(error, 'Unsubscribe user events failed', dispatch);
    }
  },
) as unknown as () => AnyAction;

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  throttle(async (_: any, { dispatch, extra }: any) => {
    try {
      await (extra.userService as UserService).deleteUser();
      dispatch(logout());
    } catch (error) {
      handleErrorThunk(error, 'Delete user failed', dispatch);
    }
  }, 1500),
) as unknown as () => AnyAction;

export const refreshCognitoToken = createAsyncThunk(
  'user/refreshCognitoToken',
  async (_, { dispatch, getState }: any) => {
    const state = getState();
    const accessToken = selectTokenExpires(state);
    const refreshAuthToken = selectRefreshToken(state);

    try {
      dispatch(updateTokenRefreshing(true));

      if (accessToken && refreshAuthToken) {
        const [cognitoUser, currentSession] = await Promise.all([
          Auth.currentAuthenticatedUser(),
          Auth.currentSession(),
        ]);
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: any, session: any) => {
          if (err) {
            console.log('Refresh token Error', err);
            dispatch(logout());
            dispatch(updateTokenRefreshing(false));
          }

          const { refreshedJwt, expiresAt, refreshToken } = getRefreshInfo(session, currentSession);
          const idToken = currentSession.getIdToken()?.getJwtToken();

          dispatch(
            makeSignIn({
              expires_at: expiresAt,
              jwt: refreshedJwt,
              checkIn: true,
              refreshToken,
              idToken,
            }),
          );
          dispatch(updateTokenRefreshing(false));
        });
      }
    } catch (e) {
      console.log('Unable to refresh Token', e);
      dispatch(logout());
      dispatch(updateTokenRefreshing(false));
    }
  },
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (params: ChangePasswordPayload, { dispatch }: any) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, params.oldPassword, params.newPassword);
      if (compareStrings(data, RequestStatus.Success)) {
        novaToast.success(_t('The password was successfully changed', 'TOAST.SUCCESS_CHANGE_PASSWORD'));
        dispatch(updateChangePasswordStatus(RequestStatus.Success));
      }
    } catch (err: any) {
      handleErrorThunk(
        handleChangePasswordError(err?.message, RequestStatus.Failed),
        'Change Password failed',
        dispatch,
      );
      dispatch(updateChangePasswordStatus(RequestStatus.Failed));
      dispatch(updateChangePasswordError(err?.message));
    }
  },
) as unknown as (params: ChangePasswordPayload) => AnyAction;
