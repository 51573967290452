import { KycProviders } from '#reducers/kyc/types';

export interface IKycConfig {
  type: KycProviders;
}

export default {
  type: KycProviders.Manual,
} as IKycConfig;

export interface IKYCConstant {
  uploadImages: string[];
}

export const KYCConstant: IKYCConstant = {
  uploadImages: ['.jpeg', '.jpg', '.png', '.pdf'],
};
