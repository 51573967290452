import { RequestStatus } from '#/types/enums';
import { OrderSummaryRender } from '#/nova/components/other/exchange/order-summary/order-summary-total/NovaOrderSummaryTotal';
import { OrderState } from './types';

export const initialStateOrder: OrderState = {
  orderRequestStatus: RequestStatus.None,
  orderEstimationStatus: RequestStatus.None,
  orderSummaryRender: {} as OrderSummaryRender,
  createConversionQuoteStatus: RequestStatus.None,
  showModalTrade: false,
  executeConversionStatus: RequestStatus.None,
};
