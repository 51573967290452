import * as gql from 'gql-query-builder';

import { ExecuteOrderPayload, EstimateOrderPayload, OrdersPayload } from '../dto';
import { orderEventFields, estimateOrderFields, subscribeOrderbookFields } from '../fields';

export * from '../dto';

export enum ConversionOperations {
  CreateOrder = 'create_order',
  CancelOrder = 'cancel_order',
  CancelAllOrders = 'cancel_all_orders',
  OpenOrders = 'open_orders',
  ClosedOrders = 'closed_orders',
  EstimateOrder = 'estimate_order',
  Orderbook = 'orderbook',
}

export default {
  executeOrder: (params: ExecuteOrderPayload) => {
    return gql.mutation({
      operation: ConversionOperations.CreateOrder,
      variables: {
        instrument_id: { value: params.instrument_id, required: true },
        type: { value: params.type, required: true, type: 'OrderType' },
        price: { value: Number(params.price), required: false, type: 'Float' },
        stop_price: {
          value: params.stop_price,
          required: false,
          type: 'Float',
        },
        side: { value: params.side, required: true, type: 'OrderSide' },
        time_in_force: {
          value: params.time_in_force,
          required: false,
          type: 'OrderTimeInForce!',
        },
        quantity: {
          value: Number(params.quantity),
          required: true,
          type: 'Float',
        },
        expires_at: { value: params.expires_at, required: false },
        quantity_mode: {
          value: params.quantity_mode,
          required: false,
          type: 'OrderQuantityMode',
        },
      },
      fields: orderEventFields,
    });
  },

  cancelOrder: (order_id: string) => {
    return gql.mutation({
      operation: ConversionOperations.CancelOrder,
      variables: {
        order_id: { value: order_id, required: true, type: 'String' },
      },
      fields: [],
    });
  },

  cancelAllOrders: () => {
    return gql.mutation({
      operation: ConversionOperations.CancelAllOrders,
      fields: orderEventFields,
    });
  },

  openOrders: (params: OrdersPayload) => {
    return gql.query({
      operation: ConversionOperations.OpenOrders,
      variables: {
        instrument_id: { value: params.instrument_id, required: false },
        status: {
          value: params.status,
          required: false,
          type: '[OrderStatus!]',
        },
        side: { value: params.side, required: false, type: 'OrderSide' },
        pager: { value: params.pager, required: false, type: 'PagerInput' },
        dateRange: {
          value: params.dateRange,
          required: false,
          type: 'DateRangeInput',
        },
        parent_order_id: {
          value: params.parent_order_id,
          required: false,
          type: 'String',
        },
      },
      fields: orderEventFields,
    });
  },

  closedOrders: (params: OrdersPayload) => {
    return gql.query({
      operation: ConversionOperations.ClosedOrders,
      variables: {
        search: { value: params.instrument_id, required: false },
        status: {
          value: params.status,
          required: false,
          type: '[OrderStatus!]',
        },
        side: { value: params.side, required: false, type: 'OrderSide' },
        pager: { value: params.pager, required: false, type: 'PagerInput' },
        dateRange: {
          value: params.dateRange,
          required: false,
          type: 'DateRangeInput',
        },
        parent_order_id: {
          value: params.parent_order_id,
          required: false,
          type: 'String',
        },
      },
      fields: [...orderEventFields, 'total'],
    });
  },

  estimateOrder: (params: EstimateOrderPayload) => {
    return gql.query({
      operation: ConversionOperations.EstimateOrder,
      variables: {
        source_currency_id: {
          value: params.source_currency_id,
          required: true,
          type: 'String',
        },
        target_currency_id: {
          value: params.target_currency_id,
          required: true,
          type: 'String',
        },
        price: { value: params.price, required: false, type: 'Float' },
        target_currency_amount: {
          value: params.target_currency_amount ? Number(params.target_currency_amount) : undefined,
          required: false,
          type: 'Float',
        },
        source_currency_amount: {
          value: params.source_currency_amount ? Number(params.source_currency_amount) : undefined,
          required: false,
          type: 'Float',
        },
        instrument_id: {
          value: params.instrument_id,
          required: false,
          type: 'String',
        },
      },
      fields: estimateOrderFields,
    });
  },
  subscribeOrderbook: (instrument_id: string) => {
    return gql.subscription({
      operation: ConversionOperations.Orderbook,
      variables: {
        instrument_id: { value: instrument_id, required: true },
      },
      fields: subscribeOrderbookFields,
    });
  },
};
