import { TransactionType, VaultTypeTransaction, PaymentTypes } from '#/types';

const formats = {
  [VaultTypeTransaction.Deposit]: PaymentTypes.Deposit,
  [VaultTypeTransaction.Withdraw]: PaymentTypes.Withdraw,
  [VaultTypeTransaction.Interest]: PaymentTypes.Interest,
};

export const formatTransactionType = (transactionType: string) => (formats as any)[transactionType] || transactionType;

export const formatTransactionTypeInObject = (transaction: { transactionType: TransactionType } | any) => {
  transaction.transactionType = formatTransactionType(transaction.transactionType);
  return transaction;
};
