import React from 'react';

import './../nova-maintenance.scss';

const NovaIpBlacklisting = () => {
  return (
    <div className='nova-maintenance'>
      <i className='far fa-4x fa-lock' style={{ marginBottom: '20px' }} aria-hidden='true'></i>
      <div className='nova-maintenance-text'>
        <h3>{_t(`You don't have access `, 'IP_BLACKLISTING.YOU_DONT_HAVE_ACCESS')}</h3>
      </div>
    </div>
  );
};

export default NovaIpBlacklisting;
