import * as gql from 'gql-query-builder';
import { Periodicity } from '#reducers/trade/enums';
import { PriceBarsPayload } from './../dto';
import {
  priceBarFields,
  getInstrumentsFields,
  getInstrumnetsPriceBars,
  instrumentBaseQuotePriceBarsFields,
  priceFields,
} from './../fields';

export * from './../dto';

export enum InstrumentOperations {
  SubscribePriceBar = 'instrument_price_bar',
  SubscribePrice = 'instrument_price',
  GetPriceBars = 'instrument_price_bars',
  Instruments = 'instruments',
}

export default {
  subscribeInstrumentPriceBar: (instrument_id: string, periodicity: Periodicity) => {
    return gql.subscription({
      operation: InstrumentOperations.SubscribePriceBar,
      variables: {
        instrument_id: { value: instrument_id, required: true },
        periodicity: {
          value: periodicity,
          required: true,
          type: 'InstrumentHistoryPeriodicity',
        },
      },
      fields: priceBarFields,
    });
  },

  subscribeInstrumentPrice: () => {
    return gql.subscription({
      operation: InstrumentOperations.SubscribePrice,
      variables: {},
      fields: priceFields,
    });
  },

  getPriceBars: (payload: PriceBarsPayload) => {
    return gql.query({
      operation: InstrumentOperations.GetPriceBars,
      variables: {
        instrument_id: { value: payload.instrument_id, required: true },
        limit: { value: payload.limit, required: false },
        date_range: {
          value: payload.date_range,
          required: false,
          type: 'DateRangeInput',
        },
        periodicity: {
          value: payload.periodicity,
          required: false,
          type: 'InstrumentHistoryPeriodicity',
        },
      },
      fields: priceBarFields,
    });
  },

  getInstrumentsPriceBars: () => {
    return gql.query({
      operation: InstrumentOperations.Instruments,
      fields: getInstrumnetsPriceBars,
      variables: {
        is_active: { is_active: true, required: false, type: 'ToggleSwitch' },
      },
    });
  },

  getInstrumentBaseQuotePriceBars: (instrument_id: string) => {
    return gql.query({
      operation: InstrumentOperations.Instruments,
      fields: instrumentBaseQuotePriceBarsFields,
      variables: {
        instrument_id: { value: instrument_id, required: true },
        is_active: { is_active: true, required: false, type: 'ToggleSwitch' },
      },
    });
  },

  getInstruments: () => {
    return gql.query({
      operation: InstrumentOperations.Instruments,
      fields: getInstrumentsFields,
      variables: {
        is_active: { is_active: true, required: false, type: 'ToggleSwitch' },
      },
    });
  },
};
