import { KycDocumentsTypes } from '#/api/settings/dto';

export enum UserKycStatus {
  Incomplete = 'incomplete',
  Rejected = 'rejected',
  Approved = 'approved',
  Submitted = 'submitted',
  NotStarted = 'not_started',
}

export enum UserAccountTypes {
  Corporate = 'corporate',
  Personal = 'individual',
}

export enum KycDataProperty {
  MobileNumber = 'mobile_nr',
}

export interface KycGetParams {
  userId: string;
  kycProperties: string[];
  kycValue?: string[];
}

export enum KycProviders {
  SumAndSubstance = 'SUM_AND_SUBSTANCE',
  BankId = 'BANK_ID',
  Manual = 'MANUAL',
}

export interface UploadKycDocumentResult {
  document_type: KycDocumentsTypes;
  original_name: string;
  content_type: string;
  s3_location: string;
  size: number;
  created_at: string;
  message?: string;
}

export interface CountryListResult {
  name: string;
  code: string;
  phone_code: string;
}

export interface ProvinceListResult {
  country_code: string;
  province_code: string | number;
  iso_region?: string | number;
  full_name: string;
  short_name: string;
  Type: string;
}

export interface KycProvidersSettings {
  individual: KycProviders;
  corporate: KycProviders;
}

export interface KycProvidersEnabled {
  individual: boolean;
  corporate: boolean;
}

export interface KycWidgetsUrl {
  individual: string;
  corporate: string;
}
