import { BigNumber } from 'bignumber.js';
import { VaultBalance as IVaultBalance, VaultProduct, Earnings, VaultEarning } from '#/types';
import { toPositiveBigNumber, getAnnualRate } from '#/util';

export const getEarning = (product: VaultProduct | undefined) =>
  product
    ? calculateVaultEarning(new BigNumber(getAnnualRate(product)))
    : {
        [Earnings.YearInterestRate]: '0',
        [Earnings.MonthInterestRate]: '0',
        [Earnings.WeekInterestRate]: '0',
        [Earnings.DayInterestRate]: '0',
      };
export const getYearInterest = (product: VaultProduct) => getEarning(product)[Earnings.YearInterestRate] || 0;

const earningDecimals = 5;
export const calculateVaultEarning = (
  annualInterestRate: BigNumber,
  decimals: number = earningDecimals,
): VaultEarning => {
  const bigNumberValue = toPositiveBigNumber(annualInterestRate);
  return {
    yearInterestRate: bigNumberValue.toFixed(decimals),
    monthInterestRate: bigNumberValue.dividedBy(12).toFixed(decimals),
    weekInterestRate: bigNumberValue.dividedBy(52).toFixed(decimals),
    dayInterestRate: bigNumberValue.dividedBy(365).toFixed(decimals),
  };
};

// ((balanceETHVault1 * InterestETHVault1) + (balanceETHVault2 * InterestETHVault2)) / ETHTotalvaultsBalance
// ( (10*2%) + (5*3%)) / 15 = 0.0233333333 = 2.33%
export const calculateVaultAssetsApy = (products: Array<VaultProduct>, balances: Array<IVaultBalance>) => {
  const allApys: {
    [key: string]: {
      totalBalance: number;
      _apySumAssets: number;
      avgApy: number;
      name: string;
    };
  } = {};
  const setAvg = (earn: number, _balance: number, assetId: string) => {
    if (!allApys[assetId]) {
      allApys[assetId] = {
        totalBalance: 0,
        _apySumAssets: 0,
        avgApy: 0,
        name: assetId,
      };
    }
    const balance = _balance || 0;
    allApys[assetId].totalBalance += Number(balance);
    allApys[assetId]._apySumAssets += Number(earn * balance);
    allApys[assetId].avgApy = allApys[assetId]._apySumAssets / allApys[assetId].totalBalance || 0;
  };
  products.forEach((product) => {
    const balance = balances.find((balance: IVaultBalance) => balance.productId === product.id)?.balance;
    setAvg(
      Number(getYearInterest(product)),
      BigNumber.isBigNumber(balance) ? balance.toNumber() : balance || 0,
      product.assetId,
    );
  });
  return allApys;
};
