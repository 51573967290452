import { zeroTimeZone } from '#/nova/components/controls/timezone/allTimezones';

// return time zone format Etc/GMT+10 Etc/GMT-10 Etc/GMT
export const getTimZoneAbrr = () => {
  const now = new Date();

  const offsetHours = now.getTimezoneOffset() / 60;

  if (offsetHours === 0) {
    return zeroTimeZone;
  }

  const sign = offsetHours >= 0 ? '+' : '-';

  return `Etc/GMT${sign}${Math.abs(offsetHours)}`;
};
