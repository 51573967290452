import React, { ReactElement, ReactNode } from 'react';
import ErrorBoundary from '#/elements/ErrorBoundary';
import './nova-component.scss';

interface Props {
  children: Array<ReactElement> | ReactElement | ReactNode;
  header?: string | ReactElement;
  className?: string;
  inRow?: boolean;
  noWrapper?: boolean;
}
const NovaComponent = (props: Props) => {
  const { children, header, className = '', inRow, noWrapper = false } = props;

  return (
    <ErrorBoundary>
      <div className={`${className} nova-component `}>
        {header && <div className='nova-component-header'>{header}</div>}
        <div className={`nova-component-content ${inRow ? 'in-row' : ''} ${noWrapper ? 'no-wrapper' : ''}`}>
          {children}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default NovaComponent;
