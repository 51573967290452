export const removeAllCookies = () => {
  // @ts-ignore
  window.cookieStore
    ?.getAll()
    .then((_cookies: any) => {
      _cookies.forEach((_: { name: string }) => {
        // @ts-ignore
        window.cookieStore?.delete(_.name);
      });
    })
    .catch((e: string) => console.log(e));
};
