import { Periodicity } from '#reducers/trade/enums';
import { ResolutionInnerChart } from './generateDataFeed';

export const getResolution = (resolution: ResolutionInnerChart) => {
  const periodicityObj = {
    '1D': Periodicity.Day,
    30: Periodicity.Minute30,
    15: Periodicity.Minute15,
    5: Periodicity.Minute5,
    1: Periodicity.Minute,
  };
  return (periodicityObj as any)[resolution] || Periodicity.Minute15;
};
