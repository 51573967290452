import React from 'react';
import { Link } from 'react-router-dom';

import './logo.scss';

const Logo = ({ isDark = false, isLoading = false }: { isDark?: boolean; isLoading?: boolean }) => {
  const renderLogo = () => <img src='/logo-full.svg' alt='logo' />;

  return <div className='logo-container'>{isLoading ? renderLogo() : <Link to='/'>{renderLogo()}</Link>}</div>;
};

export default Logo;
