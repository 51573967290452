import { localStorageTimeZoneKey } from './getTimezone';

const defaultTimezoneSettings = '{"timeZone":"","isAutoTimezone":""}';

export const getTimeZoneSettings = (): {
  timeZone: string;
  isAutoTimezone: boolean;
} => {
  const timeZoneSettings = localStorage.getItem(localStorageTimeZoneKey);
  const { timeZone, isAutoTimezone } = JSON.parse(timeZoneSettings || defaultTimezoneSettings);
  return { timeZone, isAutoTimezone };
};
