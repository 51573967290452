import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { initialDepositState } from './helpers';
import { IDepositCryptoResult, IDepositFiatFundingMethod, IFiatDepositProperty } from '#/types/interfaces';
import { RequestStatus } from '#/types/enums';
import { IDepositState } from './types';
import { FiatPaymentMethodsToRender } from '#reducers/wallets/wallets';

const depositSlice = createSlice({
  name: 'depositSlice',
  initialState: initialDepositState,
  reducers: {
    setDepositCrypto(state, action: PayloadAction<IDepositCryptoResult | null>) {
      state.depositCrypto = action.payload;
    },
    setDepositFiat(state, action: PayloadAction<IFiatDepositProperty[] | null>) {
      state.depositFiat = action.payload;
    },
    setDepositStatus(state, action: PayloadAction<RequestStatus>) {
      state.depositRequest = action.payload;
    },
    setDepositFiatFundingMethod(state, action: PayloadAction<IDepositFiatFundingMethod | null>) {
      state.depositFiat = null;
      state.depositFiatFundingMethod = action.payload;
    },
    setDepositFiatPaymentRoute(state, action: PayloadAction<FiatPaymentMethodsToRender | null>) {
      state.depositFiatPaymentRoute = action.payload;
    },
  },
});

export const {
  setDepositCrypto,
  setDepositFiat,
  setDepositStatus,
  setDepositFiatFundingMethod,
  setDepositFiatPaymentRoute,
} = depositSlice.actions;

export default depositSlice.reducer;

const selectDepositState = (state: Store): IDepositState => state.wallets.deposit;

export const selectDepositCrypto: (state: Store) => IDepositCryptoResult | null = createDraftSafeSelector(
  selectDepositState,
  (depositState: IDepositState) => depositState.depositCrypto,
);

export const selectDepositFiat: (state: Store) => IFiatDepositProperty[] | null = createDraftSafeSelector(
  selectDepositState,
  (depositState: IDepositState) => depositState.depositFiat,
);

export const selectDepositStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectDepositState,
  (depositState: IDepositState) => depositState.depositRequest,
);

export const selectDepositFiatFundingMethod: (state: Store) => IDepositFiatFundingMethod | null =
  createDraftSafeSelector(selectDepositState, (depositState: IDepositState) => depositState.depositFiatFundingMethod);

export const selectDepositFiatPaymentRoute: (state: Store) => FiatPaymentMethodsToRender | null =
  createDraftSafeSelector(selectDepositState, (depositState: IDepositState) => depositState.depositFiatPaymentRoute);
