import { isDevelopment } from '../config-env';

export default {
  TVProvider: 'Coinbase',
  hideTVLegend: true,
  theme: 'Dark',
  debug: false,
  defaultProps: {
    symbol: 'Coinbase:BTC/USD',
    interval: '15',
    containerId: 'tv_chart_container',
    libraryPath: `${isDevelopment ? '/lib/charting_library/' : '/lib/charting_library/'}`,
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  },
  disabled_features: [
    'timeframes_toolbar',
    'header_screenshot',
    'context_menus',
    'header_settings',
    'compare_symbol',
    'header_symbol_search',
    'symbol_info',
    'header_compare',
    'header_undo_redo',
    'header_indicators',
    'edit_buttons_in_legend',
    'header_saveload',
    'show_hide_button_in_legend',
    'create_volume_indicator_by_default',
    'symbol_search_hot_key',
    'study_templates',
  ],
  enabled_features: ['hide_last_na_study_output', 'disable_resolution_rebuild'],
};
