import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { TransactionResult, AccountTransaction } from '../interfaces';
import { RequestStatus } from '#/types/enums';
import { initialTransactionsState } from './helpers';
import { Store } from '#/store/defaultState';
import { ITransactionsState } from './types';

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: initialTransactionsState,
  reducers: {
    transactionsRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.transactionsRequestStatus = action.payload;
    },
    updateTransactions(state, action: PayloadAction<Array<TransactionResult>>) {
      state.transactions = action.payload;
      state.transactionsRequestStatus = RequestStatus.Success;
    },
    updateTransactionsNextPageExists(state, action: PayloadAction<boolean>) {
      state.transactionsNextPageExists = action.payload;
    },
  },
});

export const { transactionsRequestStatus, updateTransactions, updateTransactionsNextPageExists } =
  transactionsSlice.actions;

export default transactionsSlice.reducer;

export const selectTransactionState = (state: Store): ITransactionsState => state.trade.transactions;

export const selectTransactions: (store: Store) => Array<AccountTransaction> = createDraftSafeSelector(
  selectTransactionState,
  (transactionsState: ITransactionsState) => transactionsState.transactions,
);

export const selectTransactionsNextPageExists: (store: Store) => boolean = createDraftSafeSelector(
  selectTransactionState,
  (transactionsState: ITransactionsState) => transactionsState.transactionsNextPageExists,
);

export const selectTransactionsRequest: (store: Store) => RequestStatus = createDraftSafeSelector(
  selectTransactionState,
  (transactionsState: ITransactionsState) => transactionsState.transactionsRequestStatus,
);
