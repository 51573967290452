export const defaultStorageValueChartHistory = {
  style: 8,
  esdShowDividends: true,
  esdShowSplits: true,
  esdShowEarnings: true,
  esdShowBreaks: false,
  esdBreaksStyle: {
    color: 'rgba( 235, 77, 92, 1)',
    style: 2,
    width: 1,
  },
  esdFlagSize: 2,
  showCountdown: false,
  bidAsk: {
    visible: false,
    lineStyle: 1,
    lineWidth: 1,
    bidLineColor: '#2196F3',
    askLineColor: '#EF5350',
  },
  prePostMarket: {
    visible: true,
    lineStyle: 1,
    lineWidth: 1,
    preMarketColor: '#fb8c00',
    postMarketColor: '#2196f3',
  },
  highLowAvgPrice: {
    highLowPriceLinesVisible: false,
    highLowPriceLabelsVisible: false,
    averageClosePriceLineVisible: false,
    averageClosePriceLabelVisible: false,
  },
  showInDataWindow: true,
  showPriceLine: true,
  priceLineWidth: 1,
  priceLineColor: '',
  baseLineColor: '#B2B5BE',
  showPrevClosePriceLine: false,
  prevClosePriceLineWidth: 1,
  prevClosePriceLineColor: 'rgba( 85, 85, 85, 1)',
  extendedHours: false,
  dividendsAdjustment: {},
  sessVis: false,
  statusViewStyle: {
    fontSize: 16,
    showExchange: true,
    showInterval: true,
    symbolTextSource: 'description',
  },
  candleStyle: {
    upColor: '#03D5C0',
    downColor: '#F84BAD',
    drawWick: true,
    drawBorder: true,
    borderColor: '#378658',
    borderUpColor: '#03D5C0',
    borderDownColor: '#F84BAD',
    wickColor: '#737375',
    wickUpColor: '#03D5C0',
    wickDownColor: '#F84BAD',
    barColorsOnPrevClose: false,
    drawBody: true,
  },
  hollowCandleStyle: {
    upColor: '#03D5C0',
    downColor: '#F84BAD',
    drawWick: true,
    drawBorder: true,
    borderColor: 'rgba( 55, 134, 88, 1)',
    borderUpColor: '#03D5C0',
    borderDownColor: '#F84BAD',
    wickColor: 'rgba( 115, 115, 117, 1)',
    wickUpColor: '#03D5C0',
    wickDownColor: '#F84BAD',
    drawBody: true,
  },
  haStyle: {
    upColor: '#03D5C0',
    downColor: '#F84BAD',
    drawWick: true,
    drawBorder: true,
    borderColor: 'rgba( 55, 134, 88, 1)',
    borderUpColor: '#03D5C0',
    borderDownColor: '#F84BAD',
    wickColor: '#B5B5B8',
    wickUpColor: '#03D5C0',
    wickDownColor: '#F84BAD',
    showRealLastPrice: false,
    barColorsOnPrevClose: false,
    inputs: {},
    inputInfo: {},
    drawBody: true,
  },
  barStyle: {
    upColor: '#03D5C0',
    downColor: '#F84BAD',
    barColorsOnPrevClose: false,
    dontDrawOpen: false,
    thinBars: true,
  },
  hiloStyle: {
    color: '#2196f3',
    showBorders: true,
    borderColor: '#2196f3',
    showLabels: true,
    labelColor: '#2196f3',
    fontSize: 7,
    drawBody: true,
  },
  lineStyle: {
    color: '#03D5C0',
    linestyle: 0,
    linewidth: 2,
    priceSource: 'close',
    styleType: 2,
  },
  areaStyle: {
    color1: 'rgba(31, 167, 157, 0.28)',
    color2: '#03D5C0',
    linecolor: '#03D5C0',
    linestyle: 0,
    linewidth: 2,
    priceSource: 'close',
    transparency: 100,
  },
  renkoStyle: {
    upColor: '#26a69a',
    downColor: '#ef5350',
    borderUpColor: '#26a69a',
    borderDownColor: '#ef5350',
    upColorProjection: 'rgba( 169, 220, 195, 1)',
    downColorProjection: 'rgba( 245, 166, 174, 1)',
    borderUpColorProjection: 'rgba( 169, 220, 195, 1)',
    borderDownColorProjection: 'rgba( 245, 166, 174, 1)',
    wickUpColor: '#26a69a',
    wickDownColor: '#ef5350',
    inputs: {
      source: 'close',
      sources: 'Close',
      boxSize: 3,
      style: 'ATR',
      atrLength: 14,
      wicks: true,
    },
    inputInfo: {
      source: {
        name: 'source',
      },
      sources: {
        name: 'Source',
      },
      boxSize: {
        name: 'Box size',
      },
      style: {
        name: 'Style',
      },
      atrLength: {
        name: 'ATR length',
      },
      wicks: {
        name: 'Wicks',
      },
    },
  },
  pbStyle: {
    upColor: '#26a69a',
    downColor: '#ef5350',
    borderUpColor: '#26a69a',
    borderDownColor: '#ef5350',
    upColorProjection: 'rgba( 169, 220, 195, 1)',
    downColorProjection: 'rgba( 245, 166, 174, 1)',
    borderUpColorProjection: 'rgba( 169, 220, 195, 1)',
    borderDownColorProjection: 'rgba( 245, 166, 174, 1)',
    inputs: {
      source: 'close',
      lb: 3,
    },
    inputInfo: {
      source: {
        name: 'Source',
      },
      lb: {
        name: 'Number of line',
      },
    },
  },
  kagiStyle: {
    upColor: '#26a69a',
    downColor: '#ef5350',
    upColorProjection: 'rgba( 169, 220, 195, 1)',
    downColorProjection: 'rgba( 245, 166, 174, 1)',
    inputs: {
      source: 'close',
      style: 'ATR',
      atrLength: 14,
      reversalAmount: 1,
    },
    inputInfo: {
      source: {
        name: 'Source',
      },
      style: {
        name: 'Style',
      },
      atrLength: {
        name: 'ATR length',
      },
      reversalAmount: {
        name: 'Reversal amount',
      },
    },
  },
  pnfStyle: {
    upColor: '#26a69a',
    downColor: '#ef5350',
    upColorProjection: 'rgba( 169, 220, 195, 1)',
    downColorProjection: 'rgba( 245, 166, 174, 1)',
    inputs: {
      sources: 'Close',
      reversalAmount: 3,
      boxSize: 1,
      style: 'ATR',
      atrLength: 14,
      oneStepBackBuilding: false,
    },
    inputInfo: {
      sources: {
        name: 'Source',
      },
      boxSize: {
        name: 'Box size',
      },
      reversalAmount: {
        name: 'Reversal amount',
      },
      style: {
        name: 'Style',
      },
      atrLength: {
        name: 'ATR length',
      },
      oneStepBackBuilding: {
        name: 'One step back building',
      },
    },
  },
  baselineStyle: {
    baselineColor: 'rgba( 117, 134, 150, 1)',
    topFillColor1: 'rgba(3, 213, 192, 0.28)',
    topFillColor2: 'rgba(3, 213, 192, 0.05)',
    bottomFillColor1: 'rgba(248, 75, 173, 0.28)',
    bottomFillColor2: 'rgba(248, 75, 173, 0.05)',
    topLineColor: 'rgba(3, 213, 192, 1)',
    bottomLineColor: 'rgba(248, 75, 173, 1)',
    topLineWidth: 2,
    bottomLineWidth: 2,
    priceSource: 'close',
    transparency: 50,
    baseLevelPercentage: 50,
  },
  rangeStyle: {
    upColor: '#26a69a',
    downColor: '#ef5350',
    thinBars: true,
    upColorProjection: 'rgba( 169, 220, 195, 1)',
    downColorProjection: 'rgba( 245, 166, 174, 1)',
    inputs: {
      range: 10,
      phantomBars: false,
    },
    inputInfo: {
      range: {
        name: 'Range',
      },
      phantomBars: {
        name: 'Phantom bars',
      },
    },
  },
  symbol: 'SFM/USDT',
  shortName: 'SFM/USDT',
  timeframe: '',
  onWidget: false,
  interval: '15',
  currencyId: null,
  priceAxisProperties: {
    autoScale: true,
    alignLabels: true,
  },
};
