import { createAsyncThunk, AnyAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';
import TradeService from '#/api/trade/TradeService';
import { setWithdrawCrypto, setWithdrawStatus, setWithdrawFiat } from './withdraw';
import { novaToast } from '#/nova/components/other/toast/novaToast';
import { RequestStatus } from '#/types/enums';
import { throttle, handleErrorThunk, debounce } from '#/util';
import { IEstimateNetworkFeeResponse, IWithdrawCryptoResult, IWithdrawFiatResult } from '#/types/interfaces';
import { CreateWithdrawCryptoPayload, CreateWithdrawFiatPayload, EstimateNetworkFeePayload } from '#/api/trade/dto';
import { getBalances } from '#reducers/trade';
import { UserMarketCurrencies } from '#reducers/user/user';

export const createWithdrawCrypto = createAsyncThunk(
  'wallets/createWithdrawCrypto',
  throttle(
    async (
      {
        withdraw,
        marketCurrency,
      }: {
        withdraw: CreateWithdrawCryptoPayload;
        marketCurrency: UserMarketCurrencies;
      },
      { dispatch, extra }: any,
    ) => {
      dispatch(setWithdrawStatus(RequestStatus.Pending));
      try {
        const { create_withdrawal_crypto }: { create_withdrawal_crypto: IWithdrawCryptoResult } = await (
          extra.tradeService as TradeService
        ).createWithdrawCrypto(withdraw);
        batch(() => {
          dispatch(setWithdrawCrypto({ ...create_withdrawal_crypto, amount: '' }));
          dispatch(setWithdrawStatus(RequestStatus.Success));
          dispatch(getBalances({ marketCurrency, isShowLoader: false }));
        });
        novaToast.info('Withdrawal created');
      } catch (error) {
        dispatch(setWithdrawStatus(RequestStatus.Failed));
        handleErrorThunk(error, 'Create withdraw crypto failed', dispatch);
      }
    },
    500,
  ),
) as unknown as ({
  withdraw,
  marketCurrency,
}: {
  withdraw: CreateWithdrawCryptoPayload;
  marketCurrency: UserMarketCurrencies;
}) => AnyAction;

export const createWithdrawFiat = createAsyncThunk(
  'wallets/createWithdrawFiat',
  throttle(
    async (
      {
        withdraw,
        marketCurrency,
      }: {
        withdraw: CreateWithdrawFiatPayload;
        marketCurrency: UserMarketCurrencies;
      },
      { dispatch, extra }: any,
    ) => {
      dispatch(setWithdrawStatus(RequestStatus.Pending));
      try {
        const { create_withdrawal_fiat }: { create_withdrawal_fiat: IWithdrawFiatResult } = await (
          extra.tradeService as TradeService
        ).createWithdrawFiat(withdraw);
        batch(() => {
          dispatch(setWithdrawFiat(null)); // clear field after success request
          dispatch(setWithdrawStatus(RequestStatus.Success));
          dispatch(getBalances({ marketCurrency, isShowLoader: false }));
        });
        novaToast.info('Withdrawal created');
      } catch (error) {
        dispatch(setWithdrawStatus(RequestStatus.Failed));
        handleErrorThunk(error, 'Create withdraw fiat failed', dispatch);
      }
    },
    500,
  ),
) as unknown as ({
  withdraw,
  marketCurrency,
}: {
  withdraw: CreateWithdrawFiatPayload;
  marketCurrency: UserMarketCurrencies;
}) => AnyAction;

interface EstimateNetworkFeeThunkParams {
  params: EstimateNetworkFeePayload;
  callback: (fees?: IEstimateNetworkFeeResponse) => void;
}
export const estimateNetworkFee = createAsyncThunk(
  'wallets/estimateNetworkFee',
  debounce(300, async (params: EstimateNetworkFeeThunkParams, { dispatch, extra }: any) => {
    try {
      const { estimate_network_fee } = await (extra.tradeService as TradeService).estimateNetworkFee(params.params);
      params.callback(estimate_network_fee);
    } catch (error) {
      handleErrorThunk(error, 'Estimate network fee failed!', dispatch);
      params.callback();
    }
  }),
) as unknown as (params: EstimateNetworkFeeThunkParams) => AnyAction;
