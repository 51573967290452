export const compareStrings = (string1: string, string2: string, caseSensetive: boolean = false) => {
  if (!string1 || !string2) {
    return false;
  }
  if (!caseSensetive) {
    return string1.toLowerCase() === string2.toLowerCase();
  }

  return string1 === string2;
};

export const strIncludes = (
  string1: string,
  string2: string,
  caseSensetive: boolean = false,
  ignoreSymbols = true,
  ignoreSlash = false,
) => {
  if (ignoreSymbols) {
    // eslint-disable-next-line no-useless-escape
    string1 = string1.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    // eslint-disable-next-line no-useless-escape
    string2 = string2.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  } else if (ignoreSlash) {
    string1 = string1.replace('/', '');
    string2 = string2.replace('/', '');
  }
  if (caseSensetive) {
    return string1.indexOf(string2) !== -1;
  }

  return string1.toLowerCase().indexOf(string2.toLowerCase()) !== -1;
};
