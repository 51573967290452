import { getTime } from '#/util';
import { InstrumentPriceBar } from '#reducers/trade/interfaces';
import { Bar } from './datafeed-api';
import getTimeframe from './getTimeframe';

export const prepareChartBars = (priceBars: Array<InstrumentPriceBar>, resolution: string): Array<Bar> => {
  return priceBars.map((_) => ({
    close: Number(_.close),
    high: Number(_.high),
    low: Number(_.low),
    open: Number(_.open),
    time: getTime(_.ts_iso) - getTimeframe(resolution),
  }));
};
