import * as gql from 'gql-query-builder';

import { ConversionQuote, GetConversionsPayload } from '../dto';
import { executeConversionFields, createConversionQuoteFields, getConversionsFields } from '../fields';

export * from '../dto';

export enum ConversionOperations {
  CreateConversionOrder = 'create_conversion_order',
  CreateConversionQuote = 'create_conversion_quote',
  GetConversions = 'conversions',
}

export enum AliasConversionOperations {
  CreateConversionOrder = 'alias_create_conversion_order', // Create conversion
  CreateConversionQuote = 'alias_create_conversion_quote', // Estimate conversion
  GetConversions = 'alias_get_conversions',
}

export default {
  executeConversion: (conversionId: string) => {
    return gql.mutation({
      operation: {
        name: ConversionOperations.CreateConversionOrder,
        alias: AliasConversionOperations.CreateConversionOrder,
      },
      variables: {
        conversion_quote_id: {
          value: conversionId,
          required: true,
          type: 'String',
        },
      },
      fields: executeConversionFields,
    });
  },

  createConversionQuote: (params: ConversionQuote) => {
    return gql.mutation({
      operation: {
        name: ConversionOperations.CreateConversionQuote,
        alias: AliasConversionOperations.CreateConversionQuote,
      },
      variables: {
        source_currency_id: {
          value: params.source_currency_id,
          required: true,
          type: 'String',
        },
        target_currency_id: {
          value: params.target_currency_id,
          required: true,
          type: 'String',
        },
        target_currency_amount: {
          value: params.target_currency_amount ? Number(params.target_currency_amount) : undefined,
          required: false,
          type: 'Float',
        },
        source_currency_amount: {
          value: params.source_currency_amount ? Number(params.source_currency_amount) : undefined,
          required: false,
          type: 'Float',
        },
      },
      fields: createConversionQuoteFields,
    });
  },

  getConversions: (params: GetConversionsPayload) => {
    return gql.query({
      operation: {
        name: ConversionOperations.GetConversions,
        alias: AliasConversionOperations.GetConversions,
      },
      variables: {
        search: {
          value: params.source_currency_id,
          required: false,
          type: 'String',
        },
        pager: { value: params.pager, required: false, type: 'PagerInput' },
        dateRange: {
          value: params.dateRange,
          required: false,
          type: 'DateRangeInput',
        },
      },
      fields: getConversionsFields,
    });
  },
};
