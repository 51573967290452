import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';
import { Store } from '#/store/defaultState';
import { IsActive, RequestStatus } from '#/types/enums';
import { IBalanceExtended, Balances, PaymentRoute } from '../interfaces';
import { BalancesState } from './types';
import { getBalanceQuote, initialStateBalances } from './helpers';
import { selectFavoriteInstrumentsIds, selectUserMarketCurrency } from '#reducers/user/user/user';
import { UserMarketCurrencies } from '#reducers/user/user';
import { selectUserDisabledCurrencies } from '../paymentLimits';

const balancesSlice = createSlice({
  name: 'balances',
  initialState: {
    ...initialStateBalances,
  },
  reducers: {
    balancesRequestStatus(state, action: PayloadAction<RequestStatus>) {
      state.balancesRequestStatus = action.payload;
    },
    updateBalances(state, action: PayloadAction<Balances>) {
      state.balances = action.payload;
      state.balancesRequestStatus = RequestStatus.Success;
    },
    updateBalance(state, action: PayloadAction<IBalanceExtended>) {
      state.balances[action.payload.currency_id] = action.payload;
    },
  },
});

export const { balancesRequestStatus, updateBalances, updateBalance } = balancesSlice.actions;

export default balancesSlice.reducer;

export const selectBalancesState = (state: Store): BalancesState => state.trade.balances;

export const selectBalancesAllowed: (state: Store) => Balances = createDraftSafeSelector(
  selectBalancesState,
  selectUserDisabledCurrencies,
  (balances: BalancesState, disabledCurrencies: Array<string>) =>
    [...Object.values(balances.balances)].reduce(
      (acc, _) => {
        if (!disabledCurrencies.includes(_.currency_id)) {
          acc[_.currency_id] = _;
        }
        return acc;
      },
      {} as { [key: string]: IBalanceExtended },
    ),
);

export const selectBalances: (state: Store) => Balances = createDraftSafeSelector(
  selectBalancesAllowed,
  (balances: Balances) => balances,
);

export const selectBalancesArray: (state: Store) => Array<IBalanceExtended> = createDraftSafeSelector(
  selectBalances,
  (balances: Balances) =>
    [...Object.values(balances)].sort((a, b) => {
      if (b.balanceInQuoteTotal < a.balanceInQuoteTotal) {
        return -1;
      } else if (b.balanceInQuoteTotal > a.balanceInQuoteTotal) {
        return 1;
      } else {
        return 0;
      }
    }),
);

export const selectBalancesNotZeroArray: (state: Store) => Array<IBalanceExtended> = createDraftSafeSelector(
  selectBalancesArray,
  (balances: Array<IBalanceExtended>) => [...balances].filter((_) => _.total_balance > 0),
);

export const selectTotalBalanceInQuote: (state: Store) => number = createDraftSafeSelector(
  selectBalancesArray,
  selectUserMarketCurrency,
  (balances: Array<IBalanceExtended>, currency: UserMarketCurrencies) => {
    return balances.reduce((acc, _) => (acc += getBalanceQuote(_, currency)), 0);
  },
);

export const selectTotalFullBalance: (state: Store) => number = createDraftSafeSelector(
  selectBalancesArray,
  (balances: Array<IBalanceExtended>) => {
    return balances.reduce((acc, _) => (acc += _.balanceInQuoteTotal), 0);
  },
);

export const selectBalancesArraySorted: (state: Store) => Array<IBalanceExtended> = createDraftSafeSelector(
  selectBalancesArray,
  selectFavoriteInstrumentsIds,
  (balances: Array<IBalanceExtended>, favorites: Array<string>) =>
    [...balances].sort((a, b) => {
      if (favorites.includes(a.currency_id) && !favorites.includes(b.currency_id)) {
        return -1;
      } else if (!favorites.includes(a.currency_id) && favorites.includes(b.currency_id)) {
        return 1;
      } else {
        return 0;
      }
    }),
);

export const selectBalancesArrayNotFiat: (state: Store) => Array<IBalanceExtended> = createDraftSafeSelector(
  selectBalancesArray,
  (balances: Array<IBalanceExtended>) => balances.filter((_) => !_.isFiat),
);

export const selectBalancesArrayOnlyFiat: (state: Store) => Array<IBalanceExtended> = createDraftSafeSelector(
  selectBalancesArray,
  (balances: Array<IBalanceExtended>) => balances.filter((_) => !!_.isFiat),
);

export const selectBalancesRequestStatus: (state: Store) => RequestStatus = createDraftSafeSelector(
  selectBalancesState,
  (state: BalancesState) => state.balancesRequestStatus,
);

export const selectBalancesIsLoading: (state: Store) => boolean = createDraftSafeSelector(
  selectBalancesRequestStatus,
  (status: RequestStatus) => [RequestStatus.None, RequestStatus.Pending].includes(status),
);

export const selectCurrencyPaymentMethods: (
  state: Store,
) => (currency: string, isDevelopment: boolean) => Array<PaymentRoute> = createDraftSafeSelector(
  selectBalances,
  (balances: Balances) => (currency: string, isDevelopment) => {
    if (currency && Object.keys(balances).length && balances[currency]?.currency?.payment_routes) {
      return balances[currency]?.currency?.payment_routes.filter(
        (paymentRoute: PaymentRoute) =>
          paymentRoute.is_active === IsActive.On && (isDevelopment || paymentRoute.is_development === IsActive.Off),
      );
    } else return [];
  },
);

export const selectUserMarketCurrencyPrecision: (state: Store) => number = createDraftSafeSelector(
  selectUserMarketCurrency,
  selectBalances,
  (currency: UserMarketCurrencies, balances: Balances) => balances[currency]?.currency?.precision || 2,
);

export const selectSortedBalancesByAlphabet: (state: Store) => Array<IBalanceExtended> = createDraftSafeSelector(
  selectBalancesArray,
  (balancesArray: Array<IBalanceExtended>) =>
    [...balancesArray].sort((a, b) => a.currency_id.localeCompare(b.currency_id)),
);

export const selectSortedBalancesByBalance: (sortedByBalance: number) => (state: Store) => Array<IBalanceExtended> = (
  sortedByBalance,
) =>
  createDraftSafeSelector(selectBalancesArray, (balances: Array<IBalanceExtended>) =>
    [...balances].sort((a, b) => {
      if (sortedByBalance === -1) {
        return a.free_balance - b.free_balance;
      } else {
        return b.free_balance - a.free_balance;
      }
    }),
  );
