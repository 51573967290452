import { Article } from '#reducers/home/articles';

export class MakeRequest {
  protected makeRequest(url: string, payload: Object, method = 'POST', headers = {}) {
    return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      method,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        throw err;
      });
  }
}

export default class ThirdPartyApi extends MakeRequest {
  public async loadArticles(): Promise<{ articles: Array<Article> }> {
    try {
      const result = await this.makeRequest('https://min-api.cryptocompare.com/data/v2/news/?lang=EN', {});
      return Promise.resolve({ articles: result.Data });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
