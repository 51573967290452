import { createAsyncThunk } from '@reduxjs/toolkit';
import TradeService from '#/api/trade/TradeService';
import { Orderbook } from './types';
import { handleErrorThunk } from '#/util';
import { replaceOrderbook } from './orderbook';

export const subscribeOrderbook = createAsyncThunk(
  'trade/subscribeOrderbook',
  async (instrument_id: string, { dispatch, extra }: any) => {
    try {
      (extra.tradeService as TradeService).subscribeOrderbook(instrument_id, {
        next: ({ data: { orderbook } }: { data: { orderbook: Orderbook } }) => {
          dispatch(replaceOrderbook(orderbook));
        },
        error: (error: any) => {
          handleErrorThunk(error, 'Subscribe orderbook failed', dispatch);
        },
        complete: () => {},
      });
    } catch (error) {
      handleErrorThunk(error, 'Subscribe orderbook failed', dispatch);
    }
  },
) as (instrument_id: string) => any;
