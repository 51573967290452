import { BalancesState, initialStateBalances } from './balances';
import { IConversionsState, initialConversionsState } from './conversions';
import { InstrumentsState, initialStateInstruments } from './instruments';
import { OrderState, initialStateOrder } from './order';
import { OrderbookState, initialStateOrderbook } from './orderbook';
import { OrdersState, initialOrdersState } from './orders';
import { IPaymentFeesState, initialPaymentFeesState } from './paymentFees';
import { initialPaymentLimitsState, IPaymentLimitsState } from './paymentLimits';
import { initialTransactionsState, ITransactionsState } from './transactions';

export interface ITradeState {
  orders: OrdersState;
  orderbook: OrderbookState;
  order: OrderState;
  balances: BalancesState;
  instruments: InstrumentsState;
  conversions: IConversionsState;
  transactions: ITransactionsState;
  paymentLimits: IPaymentLimitsState;
  paymentFees: IPaymentFeesState;
}

export const initialTradeState: ITradeState = {
  balances: initialStateBalances,
  paymentLimits: initialPaymentLimitsState,
  instruments: initialStateInstruments,
  order: initialStateOrder,
  orderbook: initialStateOrderbook,
  orders: initialOrdersState,
  conversions: initialConversionsState,
  transactions: initialTransactionsState,
  paymentFees: initialPaymentFeesState,
};
