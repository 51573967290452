import { createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit';

import { Store } from '#/store/defaultState';
import { initialWalletsState } from './helpers';
import { IWalletsState } from './types';

const walletsSlice = createSlice({
  name: 'walletsSlice',
  initialState: initialWalletsState,
  reducers: {
    setWalletCurrency(state, action: PayloadAction<string>) {
      state.currency = action.payload;
    },
  },
});

export const { setWalletCurrency } = walletsSlice.actions;

export default walletsSlice.reducer;

const selectWalletsState = (state: Store): IWalletsState => state.wallets.wallets;

export const selectWalletCurrency: (state: Store) => string = createDraftSafeSelector(
  selectWalletsState,
  (walletsState: IWalletsState) => walletsState.currency,
);
