import {
  ISelectedInstrument,
  OrderEstimation,
  OrderResult,
  ExecutedConversionResult,
  IWithdrawCryptoResult,
  IPaymentResult,
} from '#/types/interfaces';
import {
  AccountTransaction,
  ConversionHistory,
  IBalance,
  IEstimateNetworkFeeResponse,
  InstrumentPriceBar,
  IWithdrawFiatResult,
  PaymentRoutes,
  GetOrders,
  ConversionResult,
  IDepositCryptoResult,
} from '#reducers/trade/interfaces';
import { Periodicity } from '#reducers/trade/enums';
import { GqlClient, GqlConnection } from '../gql';
import { RequestCallbacks, HeadersClient } from '../gql/connection';
import tradeQql, {
  ConversionQuote,
  PriceBarsPayload,
  EstimateOrderPayload,
  EstimatePaymentFeePayload,
  ExecuteOrderPayload,
  CreateDepositCryptoPayload,
  CreateWithdrawCryptoPayload,
  GetPaymentsPayload,
  CreateWithdrawFiatPayload,
  GetConversionsPayload,
  GetTransactionsPayload,
  GetPortfolioHistoryParams,
  CreatePaymentSession,
  EstimateNetworkFeePayload,
} from './trade-qql';
import { PortfolioHistoryItem } from '#reducers/home/portfolio-history';

export interface ClientWithInstrument {
  instrument: string | null;
  client: GqlConnection;
}

export default class TradeService {
  public gqlRequestClient: GqlClient;
  public orderBookWsClient: ClientWithInstrument;
  public userEventsWsClient: GqlConnection;
  public priceBars: { [instrument: string]: GqlConnection };
  public priceBarsMarkets: GqlConnection;

  constructor(gqlRequestClient: GqlClient) {
    this.priceBars = {};
    this.priceBarsMarkets = new GqlConnection();
    this.gqlRequestClient = gqlRequestClient;
    this.userEventsWsClient = new GqlConnection(this._getGqlHeaders());
    this.orderBookWsClient = {
      client: new GqlConnection(this._getGqlHeaders()),
      instrument: null,
    };
  }

  private _getGqlHeaders(headers?: HeadersClient, authorization?: string) {
    return {
      ...(headers || {}),
      authorization: authorization || this.gqlRequestClient.authorization,
    };
  }

  public getPortfolioHistory(
    params: GetPortfolioHistoryParams,
  ): Promise<{ portfolio_history: Array<PortfolioHistoryItem> }> {
    return this.gqlRequestClient.request(tradeQql.getPortfolioHistory(params));
  }

  public getBalances(): Promise<{
    alias_get_accounts_balances: Array<IBalance>;
  }> {
    return this.gqlRequestClient.request(tradeQql.getBalances());
  }

  public getInstruments(): Promise<{
    instruments: Array<ISelectedInstrument>;
  }> {
    return this.gqlRequestClient.request(tradeQql.getInstruments());
  }

  public getInstrumentBaseQuotePriceBars(instrument_id: string): Promise<{ instruments: Array<ISelectedInstrument> }> {
    return this.gqlRequestClient.request(tradeQql.getInstrumentBaseQuotePriceBars(instrument_id));
  }

  public getInstrumentsPriceBars(): Promise<{
    instruments: Array<ISelectedInstrument>;
  }> {
    return this.gqlRequestClient.request(tradeQql.getInstrumentsPriceBars());
  }

  public getPriceBars(params: PriceBarsPayload): Promise<{ instrument_price_bars: Array<InstrumentPriceBar> }> {
    return this.gqlRequestClient.request(tradeQql.getPriceBars(params));
  }

  public createConversionQuote(params: ConversionQuote): Promise<{ alias_create_conversion_quote: ConversionResult }> {
    return this.gqlRequestClient.request(tradeQql.createConversionQuote(params));
  }

  public getPayments(params: GetPaymentsPayload): Promise<{ payments: Array<IPaymentResult> }> {
    return this.gqlRequestClient.request(tradeQql.getPayments(params));
  }

  public executeConversion(conversionId: string): Promise<{ alias_create_conversion_order: ExecutedConversionResult }> {
    return this.gqlRequestClient.request(tradeQql.executeConversion(conversionId));
  }

  public estimateMarketOrder(params: EstimateOrderPayload): Promise<{ estimate_order: OrderEstimation }> {
    return this.gqlRequestClient.request(tradeQql.estimateOrder(params));
  }

  public estimateStopLimitOrder(params: EstimateOrderPayload): Promise<{ estimate_order: OrderEstimation }> {
    return this.gqlRequestClient.request(tradeQql.estimateOrder(params));
  }

  public estimatePaymentFee(params: EstimatePaymentFeePayload): Promise<{ estimate_payment_fee: number }> {
    return this.gqlRequestClient.request(tradeQql.estimatePaymentFee(params));
  }

  public estimateNetworkFee(
    params: EstimateNetworkFeePayload,
  ): Promise<{ estimate_network_fee: IEstimateNetworkFeeResponse }> {
    return this.gqlRequestClient.request(tradeQql.estimateNetworkFee(params));
  }

  public createPaymentSession(params: CreatePaymentSession): Promise<{ create_payment_session: string }> {
    return this.gqlRequestClient.request(tradeQql.createPaymentSession(params));
  }

  // Execute Market \ Stop \ Limit order
  public executeOrder(params: ExecuteOrderPayload): Promise<{ create_order: OrderResult }> {
    return this.gqlRequestClient.request(tradeQql.executeOrder(params));
  }

  public cancelOrder(orderId: string): Promise<{ cancel_order: OrderResult }> {
    return this.gqlRequestClient.request(tradeQql.cancelOrder(orderId));
  }

  public cancelAllOrders(): Promise<{ cancel_all_orders: Array<OrderResult> }> {
    return this.gqlRequestClient.request(tradeQql.cancelAllOrders());
  }

  public getConversions(params: GetConversionsPayload): Promise<{ alias_get_conversions: Array<ConversionHistory> }> {
    return this.gqlRequestClient.request(tradeQql.getConversions(params));
  }

  public getTransactions(params: GetTransactionsPayload): Promise<{ account_transactions: Array<AccountTransaction> }> {
    return this.gqlRequestClient.request(tradeQql.getTransactions(params));
  }

  public openOrders(params: GetOrders): Promise<{ open_orders: Array<OrderResult> }> {
    return this.gqlRequestClient.request(tradeQql.openOrders(params));
  }

  public closedOrders(params: GetOrders): Promise<{ closed_orders: Array<OrderResult> }> {
    return this.gqlRequestClient.request(tradeQql.closedOrders(params));
  }

  public createDepositCrypto(
    params: CreateDepositCryptoPayload,
  ): Promise<{ deposit_address_crypto: IDepositCryptoResult }> {
    return this.gqlRequestClient.request(tradeQql.createDepositCrypto(params));
  }

  public createWithdrawCrypto(
    params: CreateWithdrawCryptoPayload,
  ): Promise<{ create_withdrawal_crypto: IWithdrawCryptoResult }> {
    return this.gqlRequestClient.request(tradeQql.createWithdrawCrypto(params));
  }

  public createWithdrawFiat(
    params: CreateWithdrawFiatPayload,
  ): Promise<{ create_withdrawal_fiat: IWithdrawFiatResult }> {
    return this.gqlRequestClient.request(tradeQql.createWithdrawFiat(params));
  }

  public getFiatDepositBankDetails({
    payment_route_id,
  }: {
    payment_route_id: string;
  }): Promise<{ payments_routes: PaymentRoutes }> {
    return this.gqlRequestClient.request(tradeQql.getFiatDepositBankDetails({ payment_route_id }));
  }

  // Subscribe / Unsubscribe Instrument Price Bars
  public subscribeInstrumentPriceBar(
    {
      instrument,
      periodicity,
      unsubscribe,
    }: {
      instrument: string | Array<string>;
      periodicity: Periodicity;
      unsubscribe?: boolean;
    },
    callbacks: RequestCallbacks,
  ) {
    const subscribeFn = (_instrument: string) => {
      if (!this.priceBars[_instrument]) {
        this.priceBars[_instrument] = new GqlConnection(this._getGqlHeaders());
        this.priceBars[_instrument].subscribe(
          tradeQql.subscribeInstrumentPriceBar(_instrument, periodicity),
          callbacks,
        );
      }
    };

    const unsubscribeFn = (_instrument: string) => {
      this.priceBars[_instrument]?.disconnect();
      this.priceBars[_instrument] = undefined as unknown as GqlConnection;
    };

    const subscribeUnsubscribeFn = unsubscribe ? unsubscribeFn : subscribeFn;

    Array.isArray(instrument)
      ? instrument.forEach((_instrument) => subscribeUnsubscribeFn(_instrument))
      : subscribeUnsubscribeFn(instrument);
  }

  public subscribeInstrumentPrice({ unsubscribe }: { unsubscribe?: boolean }, callbacks: RequestCallbacks) {
    const subscribeFn = () => {
      this.priceBarsMarkets = new GqlConnection(this._getGqlHeaders());
      this.priceBarsMarkets.subscribe(tradeQql.subscribeInstrumentPrice(), callbacks);
    };

    const unsubscribeFn = () => {
      this.priceBarsMarkets?.disconnect();
      this.priceBarsMarkets = undefined as unknown as GqlConnection;
    };

    const subscribeUnsubscribeFn = unsubscribe ? unsubscribeFn : subscribeFn;
    subscribeUnsubscribeFn();
  }

  // If we get WS events - we need to show messages / update balances / etc
  public subscribeUserEvents(callbacks: RequestCallbacks) {
    this.userEventsWsClient.subscribe(tradeQql.subscribeTradeEvents(), callbacks);
  }

  public unsubscribeUserEvents() {
    this.userEventsWsClient?.disconnect();
  }

  // We can't have more than 1 orderbook in time (no reason to have additional subscription posibility)
  public subscribeOrderbook(instrument_id: string, callbacks: RequestCallbacks) {
    if (instrument_id !== this.orderBookWsClient.instrument && instrument_id) {
      this.orderBookWsClient.instrument = instrument_id;
      this.orderBookWsClient.client.subscribe(tradeQql.subscribeOrderbook(instrument_id), callbacks);
    }
  }

  public unsubscribeOrderbook() {
    this.orderBookWsClient.instrument = null;
    this.orderBookWsClient.client?.disconnect();
  }

  // TODO maybe find better way
  public reconnectWithAuthorization(_authorization: string | undefined) {
    const authorization = _authorization || this.gqlRequestClient.authorization || '';

    const resubscribe = (client: GqlConnection) => {
      client?.setAuthorization(authorization);
      client?.resubscribe && client.resubscribe();
    };

    authorization ? resubscribe(this.userEventsWsClient) : this.userEventsWsClient.disconnect(); // This subscriptions requires token
    resubscribe(this.orderBookWsClient.client);
    for (const barSubscription in this.priceBars) {
      resubscribe(this.priceBars[barSubscription]);
    }
  }
}
