import { getTime } from '#/util';
import { RequestStatus } from '#/types/enums';

export enum AuthStorageItems {
  Token = 'access_token',
  Expires_at = 'token_expires_at',
  PrevActionTime = 'prevActionTime',
}

export interface AuthStorage {
  token: string;
  expires_at: number | string; // timestamp
}

export const sessionInterval = 1000 * 60 * 60 * 24; // 24 Hour

export default {
  getAuthFromStorage: () => {
    const tokenExpiresAt = localStorage.getItem(AuthStorageItems.Expires_at);
    const accessToken = localStorage.getItem(AuthStorageItems.Token) || '';

    if (!tokenExpiresAt || !accessToken || Date.now() >= getTime(new Date(Number(tokenExpiresAt || 0)))) {
      return {
        accessToken: '',
        tokenExpiresAt: '',
        authStatus: RequestStatus.None,
      };
    }

    return {
      accessToken,
      tokenExpiresAt,
      authStatus: RequestStatus.Success,
    };
  },

  setAuthToStorage: (params: AuthStorage) => {
    localStorage.setItem(AuthStorageItems.Expires_at, String(params.expires_at));
    localStorage.setItem(AuthStorageItems.Token, String(params.token));
  },
};

export const getRefreshInfo = (session: any, currentSession: any) => {
  const refreshedJwt = session?.accessToken?.jwtToken;
  const expiresAt = Number(session?.accessToken?.payload?.exp) * 1000;
  const refreshToken = currentSession?.getRefreshToken()?.getToken();

  return {
    refreshedJwt,
    expiresAt,
    refreshToken,
  };
};
