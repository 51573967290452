import { createAsyncThunk } from '@reduxjs/toolkit';

import CryptoPayApi from '#/api/cryptoPay';
import Config from '#/config/config';

import { isStatusInclude } from './util';

import { novaToast } from '#/nova/components/other/toast/novaToast';

const {
  cryptoPayConfig: { failRequestMessage, successRequestMessage },
} = Config;

export const getWebhooks = createAsyncThunk(
  'fee/getWebhooks',
  async (_: undefined, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).getWebhooks();

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Get Webhooks - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);

export const addWebhook = createAsyncThunk(
  'fee/addWebhook',
  async (data: any, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).addWebhook(data);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Add Webhooks - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getWebhooks());

      novaToast.success(`Add Webhook - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);

export const deleteWebhookById = createAsyncThunk(
  'fee/deleteWebhookById',
  async (webhookId: string, { rejectWithValue, dispatch, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).deleteWebhookById(webhookId);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Delete Webhook - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      dispatch(getWebhooks());

      novaToast.success(`Delete Webhook - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);

export const updateWebhookById = createAsyncThunk(
  'fee/updateWebhookById',
  async (webhookId: string, { rejectWithValue, extra }: any) => {
    try {
      const response = await (extra.cryptoPayApi as CryptoPayApi).updateWebhookById(webhookId);

      if (!response.ok) {
        const isCurrentCode = isStatusInclude(response.status);
        const json = isCurrentCode ? await response.json() : {};

        novaToast.error(
          `Code: ${response.status}. Updated Webhook - Fail: ${isCurrentCode ? json.message : failRequestMessage}`,
        );
        return rejectWithValue({ code: response.status, ...json });
      }

      novaToast.success(`Updated Webhook - Success: ${successRequestMessage}`);
      return await response.json();
    } catch (error) {
      novaToast.error('error');
      return rejectWithValue(error);
    }
  },
);
